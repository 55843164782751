import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { StockRepository } from '@/wms/domain/stock/StockRepository';
import { Stock } from '@/wms/domain/stock/Stock';
import { StockDocumentsLin } from '@/wms/domain/stock/StockDocumentsLin';

@Injectable()
export class StockServices implements StockRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_QUERY_STOCK: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_QUERY_STOCK;
  private readonly API_STOCK_MOVES: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_STOCK_MOVES;
  private readonly API_FINDBY_DATERANGEANDSTORE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_FINDBY_DATERANGEANDSTORE;
  private readonly API_REFERENCE_FIND_STOCK: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_STOCK;
  private readonly API_CALCULATE_AVERAGE_COST: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_CALCULATE_AVERAGE_COST;
  private readonly API_FREIGHT_STOCK_MOVES: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_FREIGHT_STOCK_MOVES;
  private readonly API_FREIGTH_FIND_BY_DATE_RANGE_AND_STORE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_FREIGTH_FIND_BY_DATE_RANGE_AND_STORE;

  async findAll(): Promise<Stock[]> {
    try {
      return await http.get<Stock[]>(this.API_QUERY_STOCK);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findStockMovements(payload: any): Promise<any[]> {
    return await http.get<any[]>(
      `${this.API_STOCK_MOVES}?fMonth=${payload.fMonth}&iMonth=${payload.iMonth}&sku=${payload.sku}&storeId=${payload.store}&year=${payload.year}`
    );
  }

  async findReferenceStock(payload: any): Promise<any[]> {
    return await http.get<any[]>(
      `${this.API_REFERENCE_FIND_STOCK}?sku=${payload.reference}&iMonth=${payload.iMonth}&store=${payload.store}`
    );
  }

  async findByDateRangeAndStore(payload: any): Promise<Stock[]> {
    return await http.get<Stock[]>(
      `${this.API_FINDBY_DATERANGEANDSTORE}?fMonth=${payload.fMonth}&iMonth=${payload.iMonth}&store=${
        payload.store
      }&year=${payload.year}${payload.reference && `&code=${payload.reference}`}`
    );
  }

  async calculateAverageCost(payload: any): Promise<any[]> {
    return await http.get<any[]>(`${this.API_CALCULATE_AVERAGE_COST}?code=${payload.reference}`);
  }

  findFreightStocklMovements(payload: {
    fMonth: string;
    iMonth: string;
    serial: string;
    store: string;
    year: string;
    entity: string;
  }): Promise<StockDocumentsLin[]> {
    try {
      const res = http.get<StockDocumentsLin[]>(
        `${this.API_FREIGHT_STOCK_MOVES}?fMonth=${payload.fMonth}&iMonth=${payload.iMonth}&storeId=${
          payload.store
        }&year=${payload.year}${payload.serial.length > 0 ? `&serial=${payload.serial}` : ''}${
          payload.entity.length > 0 ? `&entity=${payload.entity}` : ''
        }`
      );

      return res;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  findFreigthByDateRangeAndStore(payload: {
    fMonth: string;
    iMonth: string;
    serial: string;
    store: string;
    year: string;
    entity: string;
  }): Promise<Stock[]> {
    try {
      return http.get<Stock[]>(
        `${this.API_FREIGTH_FIND_BY_DATE_RANGE_AND_STORE}?fMonth=${payload.fMonth}&iMonth=${payload.iMonth}&store=${
          payload.store
        }&year=${payload.year}${payload.serial.length > 0 ? `&serial=${payload.serial}` : ''}${
          payload.entity.length > 0 ? `&entity=${payload.entity}` : ''
        }`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
