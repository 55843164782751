import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Store } from '@/wms/domain/layout/store/Store';
import { StoreRepository } from '@/wms/domain/layout/store/StoreRepository';

@Injectable()
export class FindAllStore extends Query<Promise<Store[]>> {
  public constructor(
    @Inject(TYPES.STORE_REPOSITORY)
    private readonly storeRepository: StoreRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(): Promise<Store[]> {
    const dataStore = await this.storeRepository.findAll();
    this.stateManager.patch({ dataStore });
    return this.stateManager.state.dataStore;
  }
}
