import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Operation } from '@/tracking/domain/typeoperation/Operation';
import { OperationRepository } from '@/tracking/domain/typeoperation/OperationRepository';

@Injectable()
export class OperationFindAll extends Query<Promise<Operation[]>> {
  public constructor(
    @Inject(TYPES.TYPE_OPERATION_REPOSITORY)
    private readonly operationRepository: OperationRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(): Promise<Operation[]> {
    const dataOperation = await this.operationRepository.findAll();
    this.stateManager.patch({ dataOperation });
    return this.stateManager.state.dataOperation;
  }
}
