import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { Switches } from '@/tracking/domain/switches/Switches ';
import { SwitchesRepository } from '@/tracking/domain/switches/SwitchesRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class SwitchesServices implements SwitchesRepository {
  private readonly API_BASE_URI: string = localStorageModule().tracking.host.API_BASE_URI;
  private readonly API_SWITCHES_FINDALL: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_SWITCHES_FINDALL;
  private readonly API_SWITCHES_FINDALLSWITCHBYPROCESSTYPE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_SWITCHES_FINDALLSWITCHBYPROCESSTYPE;

  async findAll(): Promise<Switches[]> {
    try {
      return await http.get<Switches[]>(this.API_SWITCHES_FINDALL);
    } catch (err) {
      throw new Error(err);
    }
  }
  async findAllSwitchByProcessType(params: number): Promise<Switches[]> {
    try {
      return await http.get<Switches[]>(this.API_SWITCHES_FINDALLSWITCHBYPROCESSTYPE + params);
    } catch (err) {
      throw new Error(err);
    }
  }
}
