import { Injectable } from '@/core/di/Injectable';
import { PricingZone } from '@/settings/domain/PricingZone/PricingZone';
import { PricingZoneRepository } from '@/settings/domain/PricingZone/PricingZoneRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { http } from '@/core/http/HttpFetch';
import { RequestPricelistPayload } from '@/core/helper/RequestPricelistPayload';
import { PricingZoneResponse } from '@/settings/domain/pricingZoneResponse/PricingZoneResponse';
@Injectable()
export class PricingZoneServices implements PricingZoneRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_PRICING_ZONE_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICING_ZONE_SAVE;
  private readonly API_PRICING_ZONE_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICING_ZONE_UPDATE;
  private readonly API_PRICING_ZONE_FIND_ALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICING_ZONE_FIND_ALL;
  private readonly API_PRICING_ZONE_FIND_BY_STATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICING_ZONE_FIND_BY_STATUS;
  private readonly API_PRICING_ZONE_DELETE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICING_ZONE_DELETE;
  private readonly API_PRICING_ZONE_BY_ORIGIN_AND_DESTINATION: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICING_ZONE_BY_ORIGIN_AND_DESTINATION;

  async findAll(): Promise<PricingZone[]> {
    return await http.get<PricingZone[]>(this.API_PRICING_ZONE_FIND_ALL);
  }

  async save(payload: PricingZone): Promise<any> {
    return await http.post<PricingZone>(this.API_PRICING_ZONE_SAVE, JSON.stringify(payload));
  }

  async delete(payload: PricingZone): Promise<any> {
    return await http.delete<PricingZone>(this.API_PRICING_ZONE_DELETE, JSON.stringify(payload));
  }

  async update(payload: PricingZone): Promise<any> {
    return await http.put<PricingZone>(this.API_PRICING_ZONE_UPDATE, JSON.stringify(payload));
  }

  async findByStatus(status: boolean): Promise<PricingZone[]> {
    return await http.get<PricingZone[]>(`${this.API_PRICING_ZONE_FIND_BY_STATUS}${status}`);
  }

  async findByOriginAndDestination(payload: RequestPricelistPayload): Promise<PricingZoneResponse> {
    return await http.post<PricingZoneResponse>(
      this.API_PRICING_ZONE_BY_ORIGIN_AND_DESTINATION,
      JSON.stringify(payload)
    );
  }
}
