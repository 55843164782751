import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { PortRepository } from '@/freight/domain/ports/PortRepository';
import { Port } from '@/freight/domain/ports/Port';

@Injectable()
export class SearchPortsByDescriptionLike extends Query<Promise<Port[]>, any> {
  public constructor(
    @Inject(TYPES.PORT_REPOSITORY)
    private readonly portRepository: PortRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(query: any): Promise<Port[]> {
    return await this.portRepository.findPortsByDescriptionLike(query);
  }
}
