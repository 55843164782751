import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { TimelineConfigRepository } from '@/tracking/domain/timelineConfig/TimelineConfigRepository';
import { TimelineConfig } from '@/tracking/domain/timelineConfig/TimelineConfig';

@Injectable()
export class TimelineConfigServices implements TimelineConfigRepository {
  private readonly API_BASE_URI: string = localStorageModule().tracking.host.API_BASE_URI;
  private readonly API_TIMELINECONFIG_SAVE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_TIMELINE_CONFIG_SAVE;
  private readonly API_TIMELINE_FIND_TIMELINE_CONFIG: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_TIMELINE_FIND_TIMELINE_CONFIG;
  private readonly API_TIMELIND_FIND_TIMELINE_CONFIG_BY_STATUS =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_TIMELINE_CONFIG_FINDALLBYSTATUS;

  async save(timelineConfig: TimelineConfig): Promise<any> {
    try {
      return await http.post<TimelineConfig[]>(this.API_TIMELINECONFIG_SAVE, JSON.stringify(timelineConfig));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(params: number): Promise<TimelineConfig[]> {
    try {
      return await http.get<TimelineConfig[]>(this.API_TIMELINE_FIND_TIMELINE_CONFIG + params);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }

  async findByStatus(params: boolean): Promise<TimelineConfig[]> {
    try {
      return await http.get<TimelineConfig[]>(this.API_TIMELIND_FIND_TIMELINE_CONFIG_BY_STATUS + params);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
