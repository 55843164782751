import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { StateManager } from '@/core/statemanager/StateManager';
import { Switches } from '@/tracking/domain/switches/Switches ';
import { SwitchesRepository } from '@/tracking/domain/switches/SwitchesRepository';

@Injectable()
export class SwitchFindAllByProcessType extends Query<Promise<Switches[]>, number> {
  public constructor(
    @Inject(TYPES.SWITCH_REPOSITORY)
    private readonly switchesRepository: SwitchesRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(params: number): Promise<Switches[]> {
    const dataSwitches = await this.switchesRepository.findAllSwitchByProcessType(params);
    this.stateManager.patch({ dataSwitches });
    return this.stateManager.state.dataSwitches;
  }
}
