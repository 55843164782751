import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { TerritoriesRepository } from '@/settings/domain/views/territories/TerritoriesRepository';

@Injectable()
export class SearchTerritoryByCountryAndDescription extends Query<Promise<Territories[]>, string> {
  public constructor(
    @Inject(TYPES.VIEW_TERRITORIES_REPOSITORY)
    private readonly repository: TerritoriesRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(query: string): Promise<Territories[]> {
    const dataTerritories = await this.repository.searchTerritoryByCountryAndDescription(query);
    this.stateManager.patch({ dataTerritories });
    return this.stateManager.state.dataTerritories;
  }
}
