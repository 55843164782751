import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { TypeIdentificationRepository } from '@/settings/domain/entity/typeIdentification/TypeIdentificationRepository';
import { TypeIdentification } from '@/settings/domain/entity/typeIdentification/TypeIdentification';

@Injectable()
export class TypeIdentificationService implements TypeIdentificationRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_TYPEIDENTIFICATION_FINDALL_PUBLIC: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TYPEIDENTIFICATION_FINDALL_PUBLIC;
  private readonly API_TYPEIDENTIFICATION_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TYPEIDENTIFICATION_SAVE;
  private readonly API_TYPEIDENTIFICATION_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TYPEIDENTIFICATION_UPDATE;

  async create(typeIdentification: TypeIdentification) {
    try {
      await http.post<TypeIdentification[]>(this.API_TYPEIDENTIFICATION_SAVE, JSON.stringify(typeIdentification));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(typeIdentification: TypeIdentification) {
    try {
      await http.put<TypeIdentification[]>(this.API_TYPEIDENTIFICATION_UPDATE, JSON.stringify(typeIdentification));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAll(): Promise<TypeIdentification[]> {
    try {
      return await http.get<TypeIdentification[]>(this.API_TYPEIDENTIFICATION_FINDALL_PUBLIC);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
