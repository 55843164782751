import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { CustomFile } from '@/settings/domain/file/File';
import { FileRepository } from '@/settings/domain/file/FileRepository';

@Injectable()
export class CreateFile extends Command<Promise<any>, CustomFile> {
  public constructor(
    @Inject(TYPES.FILE_REPOSITORY)
    private readonly fileRepository: FileRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  internalExecute(file: CustomFile): Promise<any> {
    return this.fileRepository.create(file);
  }
}
