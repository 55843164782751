import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { LoginImageConfig } from '@/settings/domain/loginImageConfig/LoginImageConfig';
import { LoginImageConfigRepository } from '@/settings/domain/loginImageConfig/LoginImageConfigRepository';
import { Query } from '@/core/cqrs/Query';

@Injectable()
export class FindLoginImageConfigByTypeAndCompany extends Query<
  Promise<LoginImageConfig>,
  { type: string; companyId: string }
> {
  public constructor(
    @Inject(TYPES.LOGIN_IMAGE_CONFIG_REPOSITORY)
    private readonly repository: LoginImageConfigRepository
  ) {
    super();
  }
  async internalExecute(payload: { type: string; companyId: string }): Promise<LoginImageConfig> {
    return await this.repository.findByTypeAndCompany(payload.type, payload.companyId);
  }
}
