import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { FileRepository } from '@/settings/domain/file/FileRepository';
import { CustomFile } from '@/settings/domain/file/File';

@Injectable()
export class FileFindAll extends Query<Promise<CustomFile[]>, any> {
  public constructor(
    @Inject(TYPES.FILE_REPOSITORY)
    private readonly fileRepository: FileRepository,

    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(name: any): Promise<CustomFile[]> {
    const dataFile = await this.fileRepository.findAll(name);
    this.stateManager.patch({ dataFile });
    return this.stateManager.state.dataFile;
  }
}
