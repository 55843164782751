
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'BreadCrumbs'
})
export default class BreadCrumbs extends Vue {
  @Prop({ default: 'home' }) readonly title!: string;
  @Prop({ default: '' }) readonly subtitle!: string;
  @Prop({ default: '' }) readonly main!: string;
}
