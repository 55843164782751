import { Injectable } from '@/core/di/Injectable';
import { PriceList } from '@/settings/domain/priceList/PriceList';
import { PriceListRepositoryVolume } from '@/settings/domain/priceList/PriceListRepositoryVolume';
import { http } from '@/core/http/HttpFetch';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class PriceListVolumeServices implements PriceListRepositoryVolume {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_PRICE_LIST_VOLUME_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICE_LIST_VOLUME_FINDALL;
  private readonly API_PRICE_LIST_VOLUME_FINDALL_BY_STATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICE_LIST_VOLUME_FINDALL_BY_STATUS;
  private readonly API_PRICE_LIST_VOLUME_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICE_LIST_VOLUME_SAVE;
  private readonly API_PRICE_LIST_VOLUME_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICE_LIST_VOLUME_UPDATE;
  private readonly API_PRICE_LIST_VOLUMEN_FINDBYORIGINANDDESTINATION: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICE_LIST_VOLUMEN_FINDBYORIGINANDDESTINATION;

  async create(priceList: PriceList) {
    return await http.post<PriceList[]>(this.API_PRICE_LIST_VOLUME_SAVE, JSON.stringify(priceList));
  }
  async update(priceList: PriceList) {
    return await http.put<PriceList[]>(this.API_PRICE_LIST_VOLUME_UPDATE, JSON.stringify(priceList));
  }
  async findAlByStatus(status: boolean): Promise<PriceList[]> {
    return await http.get<PriceList[]>(this.API_PRICE_LIST_VOLUME_FINDALL_BY_STATUS + status);
  }
  async findByOriginAndDestination(payload: any): Promise<PriceList[]> {
    let zoneDest = '';
    let zoneOrig = '';
    let volumeUnit = '';
    let weightUnit = '';
    let transport = '';
    if (payload.zoneZcDestination) {
      zoneDest = `&destZoneZc=${payload.zoneZcDestination}`;
    }
    if (payload.zoneZcOrigin) {
      zoneOrig = `&originZoneZc=${payload.zoneZcOrigin}`;
    }
    if (payload.volumeUnit) {
      volumeUnit = `&volumeUnit=${payload.volumeUnit}`;
    }
    if (payload.weightUnit) {
      weightUnit = `&weightUnit=${payload.weightUnit}`;
    }
    if (payload.transport) {
      transport = `&transport=${payload.transport}`;
    }
    return await http.get<PriceList[]>(
      this.API_PRICE_LIST_VOLUMEN_FINDBYORIGINANDDESTINATION +
        `destCountry=${payload.countryDestination}&destState=${payload.stateDestination}&destCity=${payload.destCity}${zoneDest}&originCountry=${payload.countryOrigin}&originState=${payload.stateOrigin}&originCity=${payload.originCity}${zoneOrig}${volumeUnit}${weightUnit}${transport}`
    );
  }
  async findAll(): Promise<PriceList[]> {
    return await http.get<PriceList[]>(this.API_PRICE_LIST_VOLUME_FINDALL);
  }
}
