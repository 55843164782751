import { Injectable } from '@/core/di/Injectable';
import { LoginImageConfigRepository } from '@/settings/domain/loginImageConfig/LoginImageConfigRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { LoginImageConfig } from '@/settings/domain/loginImageConfig/LoginImageConfig';
import { httpFetchNoToken } from '@/core/http/HttpFetchWithoutToken';

@Injectable()
export class LoginImageConfigService implements LoginImageConfigRepository {
  private readonly API_BASE_URI: string = localStorageModule().auth.routes.API_BASE_URI;

  private readonly API_LOGIN_IMAGE_FIND_BY_TYPE_AND_COMPANY: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_PERSONALIZATION_TYPES_CONFIG_FIND_BY_COMPANY;

  async findByTypeAndCompany(type: string, companyId: string): Promise<LoginImageConfig> {
    return await httpFetchNoToken.get<LoginImageConfig>(
      `${this.API_LOGIN_IMAGE_FIND_BY_TYPE_AND_COMPANY}type=${type}&company=${companyId}`
    );
  }
}
