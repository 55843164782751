/* eslint-disable */
import { interfaces } from 'inversify';
import { container } from 'inversify-props';
import { TYPES } from '@/core/config/Types';
import { Application } from '@/core/config/Application';
import Vue, { VueConstructor } from 'vue';
import { Runner } from '@/core/runner/Runner';
import { StateManager } from '@/core/statemanager/StateManager';
import { TyStateManager } from '@/core/statemanager/TyStateManager';
import { ExecutorLink } from '@/core/runner/ExecutorLink';
import { LoggerLink } from '@/core/runner/LoggerLink';
import { Logger } from '@/core/helper/Logger';
import { ConsoleLogger } from '@/core/consolelogger/ConsoleLogger';
import { LoginRepository } from '@/courier/domain/login/LoginRepository';
import { LoginService } from '@/courier/infrastructure/service/login/LoginService';
import { LoginFindToken } from '@/courier/application/uses_cases/login/search/findtoken/LoginFindToken';
import { EventsRepository } from '@/tracking/domain/events/EventsRepository';
import { EventsServices } from '@/tracking/infrastructure/services/events/EventsServices';
import { ActivitiesRepository } from '@/tracking/domain/activities/ActivitiesRepository';
import { ActivitiesServices } from '@/tracking/infrastructure/services/activities/ActivitiesServices';
import { NoveltyRepository } from '@/tracking/domain/novelty/NoveltyRepository';
import { NoveltyServices } from '@/tracking/infrastructure/services/novelty/NoveltyServices';
import { OperationRepository } from '@/tracking/domain/typeoperation/OperationRepository';
import { OperationServices } from '@/tracking/infrastructure/services/typeoperation/OperationServices';
import { OperationFindAll } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindAll';
import { SwitchesRepository } from '@/tracking/domain/switches/SwitchesRepository';
import { SwitchesServices } from '@/tracking/infrastructure/services/switches/SwitchesServices';
import { SwitchesFindAll } from '@/tracking/application/uses_cases/switches/search/SwitchesFindAll';
import { CreateOperation } from '@/tracking/application/uses_cases/typeoperation/create/CreateOperation';
import { CreateEvents } from '@/tracking/application/uses_cases/events/create/CreateEvents';
import { UpdateOperation } from '@/tracking/application/uses_cases/typeoperation/update/UpdateOperation';
import { UpdateEvents } from '@/tracking/application/uses_cases/events/update/UpdateEvents';
import { CreateActivities } from '@/tracking/application/uses_cases/activities/create/CreateActivities';
import { EventsFindAll } from '@/tracking/application/uses_cases/events/search/EventsFindAll';
import { TimelineRepository } from '@/tracking/domain/timeline/TimelineRepository';
import { TimelineServices } from '@/tracking/infrastructure/services/timeline/TimelineServices';
import { TimelineFindAll } from '@/tracking/application/uses_cases/timeline/search/findall/TimelineFindAll';
import { CreateProcess } from '@/tracking/application/uses_cases/processtype/create/CreateProcess';
import { ProcessRepository } from '@/tracking/domain/processtype/ProcessRepository';
import { ProcessFindAll } from '@/tracking/application/uses_cases/processtype/search/ProcessFindAll';
import { UpdateProcess } from '@/tracking/application/uses_cases/processtype/update/UpdateProcess';
import { ProcessServices } from '@/tracking/infrastructure/services/processtype/ProcessServices';
import { ProcessFindById } from '@/tracking/application/uses_cases/processtype/search/ProcessFindById';
import { TransportRepository } from '@/tracking/domain/typetransport/TransportRepository';
import { TransportServices } from '@/tracking/infrastructure/services/typetransport/TransportServices';
import { TransportFindAll } from '@/tracking/application/uses_cases/typetransport/search/findall/TransportFindAll';
import { CreateTimeline } from '@/tracking/application/uses_cases/timeline/create/CreateTimeline';
import { EventsFindByProcessType } from '@/tracking/application/uses_cases/events/search/EventsFindByProcessType';
import { OperationFindByProcessType } from '@/tracking/application/uses_cases/typeoperation/search/OperationFindByProcessType';
import { UpdateTimeline } from '@/tracking/application/uses_cases/timeline/update/UpdateTimeline';
import { MonetaryTransactionRepository } from '@/tracking/domain/monetarytransaction/MonetaryTransactionRepository';
import { MonetaryTransactionServices } from '@/tracking/infrastructure/services/monetarytransaction/MonetaryTransactionServices';
import { MonetaryTransactionFindAll } from '@/tracking/application/uses_cases/monetarytransaction/search/findall/MonetaryTransactionFindAll';
import { TimelineFindByProcessType } from '@/tracking/application/uses_cases/timeline/search/findtimelinebyprocesstype/TimelineFindByProcessType';

//Andres Escorcia
import { FindChartValuesByTimeLine } from '@/tracking/application/uses_cases/timeline/search/findchartvaluesbytimeline/FindChartValuesByTimeLine';
import { CountryRepository } from '@/settings/domain/country/CountryRepository';
import { CountryServices } from '@/settings/infrastructure/services/country/CountryServices';
import { CountryFindAll } from '@/settings/application/uses_cases/country/search/CountryFindAll';
import { CreateCountry } from '@/settings/application/uses_cases/country/create/CreateCountry';
import { CountryFindByStatus } from '@/settings/application/uses_cases/country/search/CountryFindByStatus';
import { CountryUpdate } from '@/settings/application/uses_cases/country/update/CountryUpdate';
import { StateRepository } from '@/settings/domain/state/StateRepository';
import { StateServices } from '@/settings/infrastructure/services/state/StateServices';
import { StateFindAll } from '@/settings/application/uses_cases/state/search/StateFindAll';
import { CreateState } from '@/settings/application/uses_cases/state/create/CreateState';
import { StateFindByStatus } from '@/settings/application/uses_cases/state/search/StateFindByStatus';
import { StateFindByCountryCode } from '@/settings/application/uses_cases/state/search/StateFindByCountryCode';
import { StateUpdate } from '@/settings/application/uses_cases/state/update/StateUpdate';
import { ZoneRepository } from '@/settings/domain/zone/ZoneRepository';
import { ZoneServices } from '../../settings/infrastructure/services/zone/ZoneServices';
import { ZoneFindAll } from '@/settings/application/uses_cases/zone/search/ZoneFindAll';
import { CreateZone } from '@/settings/application/uses_cases/zone/create/CreateZone';
import { ZoneUpdate } from '@/settings/application/uses_cases/zone/update/ZoneUpdate';
import { ZoneFindByStatusAndCountryAndState } from '@/settings/application/uses_cases/zone/search/ZoneFindByStatusAndCountryAndState';
import { CurrencyRepository } from '@/settings/domain/currency/CurrencyRepository';
import { CurrencyServices } from '@/settings/infrastructure/services/currency/CurrencyServices';
import { CurrencyFindAll } from '@/settings/application/uses_cases/currency/search/CurrencyFindAll';
import { CreateCurrency } from '@/settings/application/uses_cases/currency/create/CreateCurrency';
import { CurrencyUpdate } from '@/settings/application/uses_cases/currency/update/CurrencyUpdate';
import { CurrencyFindById } from '@/settings/application/uses_cases/currency/search/CurrencyFindById';
import { CurrencyFactorFindAll } from '@/settings/application/uses_cases/currencyfactor/search/CurrencyFactorFindAll';
import { CurrencyFactorServices } from '@/settings/infrastructure/services/currencyfactor/CurrencyFactorServices';
import { CurrencyFactorRepository } from '@/settings/domain/currencyfactor/CurrencyFactorRepository';
import { CreateCurrencyFactor } from '@/settings/application/uses_cases/currencyfactor/create/CreateCurrencyFactor';
import { CurrencyFactorUpdate } from '@/settings/application/uses_cases/currencyfactor/update/CurrencyFactorUpdate';
import { CurrencyFactorFindByCurrencyCode } from '@/settings/application/uses_cases/currencyfactor/search/CurrencyFactorFindByCurrencyCode';
import { RoutesServices } from '@/settings/infrastructure/services/routes/RoutesServices';
import { RoutesRepository } from '@/settings/domain/routes/RoutesRepository';
import { RoutesFindAll } from '@/settings/application/uses_cases/routes/search/RoutesFindAll';
import { CreateRoutes } from '@/settings/application/uses_cases/routes/create/CreateRoutes';
import { RoutesUpdate } from '@/settings/application/uses_cases/routes/update/RoutesUpdate';
import { RouteFindById } from '@/settings/application/uses_cases/routes/search/RouteFindById';
import { RoutesFindByStatus } from '@/settings/application/uses_cases/routes/search/RoutesFindByStatus';
import { WarehouseRepository } from '@/settings/domain/warehouse/WarehouseRepository';
import { WarehouseServices } from '@/settings/infrastructure/services/warehouse/WarehouseServices';
import { WarehouseFindAll } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindAll';
import { WarehouseFindByCode } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindByCode';
import { CreateWarehouse } from '@/settings/application/uses_cases/warehouse/create/CreateWarehouse';
import { WarehouseUpdate } from '@/settings/application/uses_cases/warehouse/update/WarehouseUpdate';
import { WarehouseFindByStatus } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindByStatus';
import { CompanyServices } from '@/settings/infrastructure/services/company/CompanyServices';
import { CompanyRepository } from '@/settings/domain/company/CompanyRepository';
import { CompanyFindAll } from '@/settings/application/uses_cases/company/search/CompanyFindAll';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { CompanyUpdate } from '@/settings/application/uses_cases/company/update/CompanyUpdate';
import { CreateCompany } from '@/settings/application/uses_cases/company/create/CreateCompany';
import { FileRepository } from '@/settings/domain/file/FileRepository';
import { FileFindAll } from '@/settings/application/uses_cases/file/search/FileFindAll';
import { CreateFile } from '@/settings/application/uses_cases/file/create/CreateFile';
import { FileServices } from '@/settings/infrastructure/services/file/FileServices';
import { TaxeRepository } from '@/settings/domain/taxe/TaxeRepository';
import { TaxeFindAll } from '@/settings/application/uses_cases/taxe/search/TaxeFindAll';
import { TaxeCreate } from '@/settings/application/uses_cases/taxe/create/TaxeCreate';
import { TaxeFindByStatus } from '@/settings/application/uses_cases/taxe/search/TaxeFindByStatus';
import { TaxeUpdate } from '@/settings/application/uses_cases/taxe/update/TaxeUpdate';
import { TaxeServices } from '@/settings/infrastructure/services/taxe/TaxeServices';

//Carlos
import { PriceListRepositoryVolume } from '@/settings/domain/priceList/PriceListRepositoryVolume';
import { PriceListVolumeServices } from '@/settings/infrastructure/services/priceList/Volume/PriceListVolumeService';
import { PriceListFindAllVolume } from '@/settings/application/uses_cases/priceList/search/volume/PriceListFindAllVolume';
import { PriceListCreateVolume } from '@/settings/application/uses_cases/priceList/create/volume/PriceListCreateVolume';
import { PriceListFindAllByStatusVolume } from '@/settings/application/uses_cases/priceList/search/volume/PriceListFindAllByStatusVolume';
import { PriceListUpdateVolume } from '@/settings/application/uses_cases/priceList/update/volume/PriceListUpdateVolume';
import { PriceListRepositoryWeight } from '@/settings/domain/priceList/PriceListRepositoryWeight';
import { PriceListWeightServices } from '@/settings/infrastructure/services/priceList/Weight/PriceListWeightService';
import { PriceListFindAllWeight } from '@/settings/application/uses_cases/priceList/search/weight/PriceListFindAllWeight';
import { PriceListCreateWeight } from '@/settings/application/uses_cases/priceList/create/weight/PriceListCreateWeight';
import { PriceListFindAllByStatusWeight } from '@/settings/application/uses_cases/priceList/search/weight/PriceListFindAllByStatusWeight';
import { PriceListUpdateWeight } from '@/settings/application/uses_cases/priceList/update/weight/PriceListUpdateWeight';
import { PalletsFactorUpdate } from '@/settings/application/uses_cases/palletsFactor/update/PalletsFactorUpdate';
import { PalletsFactorCreate } from '@/settings/application/uses_cases/palletsFactor/create/PalletsFactorCreate';
import { PalletsFactorFindAll } from '@/settings/application/uses_cases/palletsFactor/search/PalletsFactorFindAll';
import { PalletsFactorRepository } from '@/settings/domain/palletsFactor/PalletsFactorRepository';
import { PalletsFactorService } from '@/settings/infrastructure/services/palletsFactor/PalletsFactorService';
import { InvoiceRepository } from '@/courier/domain/invoice/InvoiceRepository';
import { InvoiceService } from '@/courier/infrastructure/service/invoice/InvoiceService';
import { InvoiceFindByDateRange } from '@/courier/application/uses_cases/invoice/search/InvoiceFindByDateRange';
import { InvoiceUpdate } from '@/courier/application/uses_cases/invoice/update/InvoiceUpdate';
import { InvoiceFindByPk } from '@/courier/application/uses_cases/invoice/search/InvoiceFindByPk';
import { InvoiceCreate } from '@/courier/application/uses_cases/invoice/create/InvoiceCreate';
import { InvoiceFindByDateRangeAndCustomer } from '@/courier/application/uses_cases/invoice/search/InvoiceFindByDateRangeAndCustomer';
import { InvoiceFindByClientId } from '@/courier/application/uses_cases/invoice/search/InvoiceFindByClientId';
import { InvoiceFindAll } from '@/courier/application/uses_cases/invoice/search/InvoiceFindAll';
import { CustomerRepository } from '@/courier/domain/customer/CustomerRepository';
import { CustomerServices } from '@/courier/infrastructure/service/customer/CustomerServices';
import { CreateCustomer } from '@/courier/application/uses_cases/customer/create/CreateCustomer';
import { CustomerGetSecurityCode } from '@/courier/application/uses_cases/customer/search/CustomerGetSecurityCode';
import { UpdateCustomerPassword } from '@/courier/application/uses_cases/customer/update/UpdateCustomerPassword';
import { CurrencyFactorFindRateByDate } from '@/settings/application/uses_cases/currencyfactor/search/CurrencyFactorFindRateByDate';
import { PriceListFindByOriginAndDestinationWeight } from '@/settings/application/uses_cases/priceList/search/weight/PriceListFindByOriginAndDestinationWeight';
import { PriceListFindByOriginAndDestination } from '@/settings/application/uses_cases/priceList/search/volume/PriceListFindByOriginAndDestination';
import { PurchaseRepository } from '@/courier/domain/purchase/PurchaseRepository';
import { PurchaseServices } from '@/courier/infrastructure/service/purchase/PurchaseServices';
import { PurchaseScraping } from '@/courier/application/uses_cases/purchase/search/PurchaseScraping';
import { OrderRepository } from '@/courier/domain/order/OrderRepository';
import { OrderServices } from '@/courier/infrastructure/service/order/OrderServices';
import { CreateOrder } from '@/courier/application/uses_cases/order/create/CreateOrder';
import { CustomerFindById } from '@/courier/application/uses_cases/customer/search/CustomerFindById';
import { ConsecutiveFindByWarehouseAndType } from '@/courier/application/uses_cases/consecutive/search/ConsecutiveFindByWarehouseAndType';
import { ConsecutiveRepository } from '@/courier/domain/consecutive/ConsecutiveRepository';
import { ConsecutiveServices } from '@/courier/infrastructure/service/Consecutive/ConsecutiveServices';
import { OrderFindByPk } from '@/courier/application/uses_cases/order/search/OrderFindByPk';
import { OrderFindByDateRange } from '@/courier/application/uses_cases/order/search/OrderFindByDateRange';
import { EventsFindAllByTimelineId } from '@/tracking/application/uses_cases/events/search/EventsFindAllByTimelineId';
import { SwitchFindAllByProcessType } from '@/tracking/application/uses_cases/switches/search/SwitchFindAllByProcessType';
import { CreateEventsExec } from '@/tracking/application/uses_cases/events/create/CreateEventsExec';
import { UpdateOrder } from '@/courier/application/uses_cases/order/update/UpdateOrder';
import { RoleRepository } from '@/settings/domain/role/RoleRepository';
import { RoleServices } from '@/settings/infrastructure/services/role/RoleServices';
import { RoleFindById } from '@/settings/application/uses_cases/role/search/RoleFindById';
import { RoleFindAll } from '@/settings/application/uses_cases/role/search/RoleFindAll';
import { CreateRole } from '@/settings/application/uses_cases/role/create/CreateRole';
import { UpdateRole } from '@/settings/application/uses_cases/role/update/UpdateRole';
import { RoleFindMenuPlaneByRoleId } from '@/settings/application/uses_cases/role/search/RoleFindMenuPlaneByRoleId';
import { CreateInvoiceFromOrder } from '@/courier/application/uses_cases/invoice/create/CreateInvoiceFromOrder';
import { UpdateUserPassword } from '@/settings/application/uses_cases/user/update/UpdateUserPassword';
import { UserGetSecurityCode } from '@/settings/application/uses_cases/user/search/UserGetSecurityCode';
import { UserFindById } from '@/settings/application/uses_cases/user/search/UserFindById';
import { CreateUser } from '@/settings/application/uses_cases/user/create/CreateUser';
import { UserRepository } from '@/settings/domain/user/UserRepository';
import { UserServices } from '@/settings/infrastructure/services/user/UserServices';
import { OrderFindByDateRangeAndCustomerId } from '@/courier/application/uses_cases/order/search/OrderFindByDateRangeAndCustomerId';
import { UnitOfMeasurementRepository } from '@/settings/domain/unitofmeasurement/UnitOfMeasurementRepository';
import { CreateUnitOfMeasurement } from '@/settings/application/uses_cases/unitofmeasurement/create/CreateUnitOfMeasurement';
import { UnitOfMeasurementFindAll } from '@/settings/application/uses_cases/unitofmeasurement/search/UnitOfMeasurementFindAll';
import { UpdateUnitOfMeasurement } from '@/settings/application/uses_cases/unitofmeasurement/update/UpdateUnitOfMeasurement';
import { UnitOfMeasurementServices } from '@/settings/infrastructure/services/unitofmeasurement/UnitOfMeasurementServices';
import { VolumeRepository } from '@/settings/domain/volume/VolumeRepository';
import { VolumeServices } from '@/settings/infrastructure/services/volume/VolumeServices';
import { CreateVolume } from '@/settings/application/uses_cases/volume/create/CreateVolume';
import { UpdateVolume } from '@/settings/application/uses_cases/volume/update/UpdateVolume';
import { VolumeFindAll } from '@/settings/application/uses_cases/volume/search/VolumeFindAll';
import { WeightRepository } from '@/settings/domain/weight/WeightRepository';
import { WeightServices } from '@/settings/infrastructure/services/weight/WeightServices';
import { CreateWeight } from '@/settings/application/uses_cases/weight/create/CreateWeight';
import { UpdateWeight } from '@/settings/application/uses_cases/weight/update/UpdateWeight';
import { WeightFindAll } from '@/settings/application/uses_cases/weight/search/WeightFindAll';
import { CountryFindByDescriptionLike } from '@/settings/application/uses_cases/country/search/CountryFindByDescriptionLike';
import { StateFindByDescriptionLike } from '@/settings/application/uses_cases/state/search/StateFindByDescriptionLike';
import { ZoneFindByDescriptionLike } from '@/settings/application/uses_cases/zone/search/ZoneFindByDescriptionLike';
import { RoleFindMenuPlaneByRoleName } from '@/settings/application/uses_cases/role/search/RoleFindMenuPlaneByRoleName';
import { EventsFindAllConfActive } from '@/tracking/application/uses_cases/events/search/EventsFindAllConfActive';
import { SearchPortsByDescriptionLike } from '@/freight/application/port/search/SearchPortsByDescriptionLike';
import { PortRepository } from '@/freight/domain/ports/PortRepository';
import { PortService } from '@/freight/infrastructure/service/ports/PortService';

import { ContainerService } from '@/settings/infrastructure/services/container/ContainerService';
import { CreateContainer } from '@/settings/application/uses_cases/containers/create/CreateContainer';
import { UpdateContainer } from '@/settings/application/uses_cases/containers/update/UpdateContainer';
import { FindContainerById } from '@/settings/application/uses_cases/containers/search/FindContainerById';
import { FindAllContainers } from '@/settings/application/uses_cases/containers/search/FindAllContianers';
import { FindAllContainersByStatus } from '@/settings/application/uses_cases/containers/search/FindAllContainersByStatus';
import { ContainersRepository } from '@/settings/domain/containers/CotainersRepository';
import { CreatePackingUnits } from '@/settings/application/uses_cases/packing_units/create/CreatePackingUnit';
import { UpdatePackingUnits } from '@/settings/application/uses_cases/packing_units/update/UpdatePackingUnits';
import { FindPackingUnitById } from '@/settings/application/uses_cases/packing_units/search/FindPackingUnitById';
import { FindAllPackingUnits } from '@/settings/application/uses_cases/packing_units/search/FindAllPackingUnits';
import { FindAllPackingUnitsByStatus } from '@/settings/application/uses_cases/packing_units/search/FindPackingUnitsByStatus';
import { PackingUnitsRepository } from '@/settings/domain/packing_units/PackingUnitsRepository';
import { PackingUnitServices } from '@/settings/infrastructure/services/packing_units/PackingUnitServices';
import { SearchTerritoryByQueryDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByQueryDescription';
import { SearchTerritoriesByQueryParameter } from '@/settings/application/uses_cases/views/territories/SearchTerritoriesByQueryParameter';
import { TerritoriesRepository } from '@/settings/domain/views/territories/TerritoriesRepository';
import { TerritoryService } from '@/settings/infrastructure/services/views/territories/TerritoryService';
import { SearchPreEntityByFullNameLike } from '@/settings/application/uses_cases/pre_entity/search/SearchPreEntityByFullNameLike';
import { PreEntityRepository } from '@/settings/domain/pre_entity/PreEntityRepository';
import { PreEntityService } from '@/settings/infrastructure/services/pre_entity/PreEntityService';

import { CreateEntity } from '@/settings/application/uses_cases/entity/create/CreateEntity';
import { EntityFindAll } from '@/settings/application/uses_cases/entity/search/EntityFindAll';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { EntityRepository } from '@/settings/domain/entity/entity/EntityRepository';
import { EntityService } from '@/settings/infrastructure/services/entity/EntityService';
import { PricingZoneFindByStatus } from '@/settings/application/uses_cases/PricingZone/search/PricingZoneFindByStatus';
import { PricingZoneFindAll } from '@/settings/application/uses_cases/PricingZone/search/PricingZoneFindAll';
import { PricingZoneFindByOriginAndDestination } from '@/settings/application/uses_cases/PricingZone/search/PricingZoneFindByOriginAndDestination';
import { UpdatePricingZone } from '@/settings/application/uses_cases/PricingZone/update/UpdatePricingZone';
import { DeletePricingZone } from '@/settings/application/uses_cases/PricingZone/delete/DeletePricingZone';
import { SavePricingZone } from '@/settings/application/uses_cases/PricingZone/create/SavePricingZone';
import { PricingZoneRepository } from '@/settings/domain/PricingZone/PricingZoneRepository';
import { PricingZoneServices } from '@/settings/infrastructure/services/PricingZone/PricingZoneServices';
import { DeleteCommodityType } from '@/freight/application/commodity_type/delete/DeleteCommodityType';
import { CreateCommodityType } from '@/freight/application/commodity_type/create/CreateCommodityType';
import { UpdateCommodityType } from '@/freight/application/commodity_type/update/UpdateCommodityType';
import { CommodityTypeService } from '@/freight/infrastructure/service/commodity/CommodityTypeService';
import { FindCommodityTypeByStatus } from '@/freight/application/commodity_type/search/FindCommodityTypesByStatus';
import { FindAllCommodityTypes } from '@/freight/application/commodity_type/search/FindAllCommodityType';
import { CommodityTypeRepository } from '@/freight/domain/commodity_type/CommodityTypeRepository';
import { CalculatePricesCourierOrder } from '@/courier/application/uses_cases/courierOrder/calculatePrices/CalculatePricesCourierOrder';
import { CourierOrderServices } from '@/courier/infrastructure/service/courierOrder/CourierOrderServices';
import { CourierOrderRepository } from '@/courier/domain/CourierOrder/CourierOrderRepository';
import { RoutesFindByOiriginAndDestination } from '@/settings/application/uses_cases/routes/search/RoutesFindByOiriginAndDestination';
import { FindAllTimelineConfigByStatus } from '@/tracking/application/uses_cases/timelineConfig/search/findAll/FindAllTimelineConfigByStatus';
import { FindAllTimelineConfig } from '@/tracking/application/uses_cases/timelineConfig/search/findAll/FindAllTimelineConfig';
import { TimelineConfigRepository } from '@/tracking/domain/timelineConfig/TimelineConfigRepository';
import { CreateTimelineConfig } from '@/tracking/application/uses_cases/timelineConfig/create/CreateTimelineConfig';
import { TimelineConfigServices } from '@/tracking/infrastructure/services/timelineConfig/TimelineConfigServices';
import { OrderFindAllByModule } from '@/courier/application/uses_cases/order/search/OrderFindAllByModule';
import { OrderFindByClientId } from '@/courier/application/uses_cases/order/search/OrderFindByClientId';
import { OrderFindBySerial } from '@/courier/application/uses_cases/order/search/OrderFindBySerial';
import { TypeIdentificationService } from '@/settings/infrastructure/services/ParmsGeneral/TypeIdentificationService';
import { TypeIdentificationRepository } from '@/settings/domain/entity/typeIdentification/TypeIdentificationRepository';
import { TypeIdentificationFindAll } from '@/settings/application/uses_cases/parmsGeneral/typeIdentificacion/search/TypeIdentificationFindAll';
import { CreateTypeIdentification } from '@/settings/application/uses_cases/parmsGeneral/typeIdentificacion/create/CreateTypeIdentification';
import { UpdateTypeIdentification } from '@/settings/application/uses_cases/parmsGeneral/typeIdentificacion/update/UpdateTypeIdentification';
import { CompanyFindByIdPublic } from '@/settings/application/uses_cases/company/search/CompanyFindByIdPublic';
import { CompanyRepositoryPublic } from '@/settings/domain/company/CompanyRepositoryPublic';
import { CompanyServicesPublic } from '@/settings/infrastructure/services/company/CompanyServicesPublic';
import { RoleFindByCompanyId } from '@/settings/application/uses_cases/role/search/RoleFindByCompanyId';
import { EntityFindById } from '@/settings/application/uses_cases/entity/search/EntityFindById';
import { EntityFindByEmail } from '@/settings/application/uses_cases/entity/search/EntityFindByEmail';
import { CountryFindByDescriptionLikeInGeneralTerritories } from '@/settings/application/uses_cases/country/search/CountryFindByDescriptionLikeInGeneralTerritories';
import { SearchTerritoryByCountryAndDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByCountryAndDescription';
import { CheckUserExistence } from '@/settings/application/uses_cases/user/search/CheckUserExistence';
import { RoleFindByIdNoUsers } from '@/settings/application/uses_cases/role/search/RoleFindByIdNoUsers';
import { LoginImageConfigRepository } from '@/settings/domain/loginImageConfig/LoginImageConfigRepository';
import { LoginImageConfigService } from '@/settings/infrastructure/services/loginImageConfig/LoginImageConfigService';
import { FindLoginImageConfigByTypeAndCompany } from '@/settings/application/uses_cases/loginImageConfig/search/FindLoginImageConfigByTypeAndCompany';
import { StockRepository } from '@/wms/domain/stock/StockRepository';
import { StockServices } from '@/wms/infrastructure/service/stock/StockServices';
import { StockFindAll } from '@/wms/application/stock/search/FindallStock';
import { FindAllMovements } from '@/wms/application/stock/search/FindAllMovements';
import { FindReferenceStock } from '@/wms/application/stock/search/FindReferenceStock';
import { FindByDateRangeAndStore } from '@/wms/application/stock/search/FindByDateRangeAndStore';
import { CalculateAverageCost } from '@/wms/application/stock/search/CalculateAverageCost';
import { FindFreightStockByDateRangeAndStore } from '@/wms/application/stock/search/FindFreightStockByDateRangeAndStore';
import { FindFreightMovements } from '@/wms/application/stock/search/FindFreightMovements';
import { FindAllStore } from '@/wms/application/layout/store/search/FindAllStore';
import { StoreRepository } from '@/wms/domain/layout/store/StoreRepository';
import { StoreServices } from '@/wms/infrastructure/service/layout/store/StoreServices';
import { FindFile } from '@/settings/application/uses_cases/file/search/FindFile';

export class Container {
  private static _instance: Container | null = null;
  private readonly _container: interfaces.Container;
  private constructor() {
    //PreEntity
    container
      .bind<SearchPreEntityByFullNameLike>(TYPES.PRE_ENTITY_FIND_BY_DESCRIPTION_LIKE)
      .to(SearchPreEntityByFullNameLike)
      .inSingletonScope();
    container
      .bind<PreEntityRepository>(TYPES.API_PRE_ENTITY_REPOSITORY)
      .to(PreEntityService)
      .inSingletonScope();
    //-------------------------------------------------
    //Vistas
    container
      .bind<SearchTerritoryByQueryDescription>(TYPES.API_VIEW_FIND_TERRITORIES_BY_QUERY)
      .to(SearchTerritoryByQueryDescription)
      .inSingletonScope();
    container
      .bind<SearchTerritoryByCountryAndDescription>(TYPES.API_FIND_TERRITORIES_BY_COUNTRY_AND_DESCRIPTION)
      .to(SearchTerritoryByCountryAndDescription)
      .inSingletonScope();
    container
      .bind<SearchTerritoriesByQueryParameter>(TYPES.VIEW_FIND_TERRITORIES)
      .to(SearchTerritoriesByQueryParameter)
      .inSingletonScope();
    container
      .bind<TerritoriesRepository>(TYPES.VIEW_TERRITORIES_REPOSITORY)
      .to(TerritoryService)
      .inSingletonScope();
    //-------------------------------------------------
    //Packing units
    container
      .bind<CreatePackingUnits>(TYPES.CREATE_PACKING_UNITS)
      .to(CreatePackingUnits)
      .inSingletonScope();

    container
      .bind<UpdatePackingUnits>(TYPES.UPDATE_PACKING_UNITS)
      .to(UpdatePackingUnits)
      .inSingletonScope();

    container
      .bind<FindPackingUnitById>(TYPES.FIND_PACKING_UNITS_BY_ID)
      .to(FindPackingUnitById)
      .inSingletonScope();

    container
      .bind<FindAllPackingUnits>(TYPES.FIND_ALL_PACKING_UNITS)
      .to(FindAllPackingUnits)
      .inSingletonScope();

    container
      .bind<FindAllPackingUnitsByStatus>(TYPES.FIND_PACKING_UNITS_BY_STATUS)
      .to(FindAllPackingUnitsByStatus)
      .inSingletonScope();

    container
      .bind<PackingUnitsRepository>(TYPES.PACKING_UNITS_REPOSITORY)
      .to(PackingUnitServices)
      .inSingletonScope();

    //-------------------------------------------------
    //Containers
    container
      .bind<CreateContainer>(TYPES.CREATE_CONTAINER)
      .to(CreateContainer)
      .inSingletonScope();

    container
      .bind<UpdateContainer>(TYPES.UPDATE_CONTAINER)
      .to(UpdateContainer)
      .inSingletonScope();

    container
      .bind<FindContainerById>(TYPES.FIND_CONTAINER_BY_ID)
      .to(FindContainerById)
      .inSingletonScope();

    container
      .bind<FindAllContainers>(TYPES.FIND_ALL_CONTAINERS)
      .to(FindAllContainers)
      .inSingletonScope();

    container
      .bind<FindAllContainersByStatus>(TYPES.FIND_CONTAINER_BY_STATUS)
      .to(FindAllContainersByStatus)
      .inSingletonScope();

    container
      .bind<ContainersRepository>(TYPES.CONTAINER_REPOSITORY)
      .to(ContainerService)
      .inSingletonScope();
    //Entity
    container
      .bind<EntityFindFilter>(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
      .to(EntityFindFilter)
      .inSingletonScope();
    container
      .bind<EntityFindById>(TYPES.API_ENTITY_FIND_BY_ID)
      .to(EntityFindById)
      .inSingletonScope();
    container
      .bind<EntityFindByEmail>(TYPES.API_ENTITY_FIND_BY_EMAIL)
      .to(EntityFindByEmail)
      .inSingletonScope();
    container
      .bind<CreateEntity>(TYPES.API_ENTITY_SAVE)
      .to(CreateEntity)
      .inSingletonScope();
    container
      .bind<EntityRepository>(TYPES.API_ENTITY_REPOSITORY)
      .to(EntityService)
      .inSingletonScope();
    container
      .bind<EntityFindAll>(TYPES.API_ENTITY_FIND_ALL_PAGE)
      .to(EntityFindAll)
      .inSingletonScope();

    //PRICING ZONES
    container
      .bind<PricingZoneRepository>(TYPES.PRICING_ZONE_REPOSITORY)
      .to(PricingZoneServices)
      .inSingletonScope();
    container
      .bind<SavePricingZone>(TYPES.PRICING_ZONE_SAVE)
      .to(SavePricingZone)
      .inSingletonScope();
    container
      .bind<DeletePricingZone>(TYPES.PRICING_ZONE_DELETE)
      .to(DeletePricingZone)
      .inSingletonScope();
    container
      .bind<UpdatePricingZone>(TYPES.PRICING_ZONE_UPDATE)
      .to(UpdatePricingZone)
      .inSingletonScope();
    container
      .bind<PricingZoneFindAll>(TYPES.PRICING_ZONE_FIND_ALL)
      .to(PricingZoneFindAll)
      .inSingletonScope();
    container
      .bind<PricingZoneFindByStatus>(TYPES.PRICING_ZONE_FIND_BY_STATUS)
      .to(PricingZoneFindByStatus)
      .inSingletonScope();
    container
      .bind<PricingZoneFindByOriginAndDestination>(TYPES.PRICING_ZONE_FIND_BY_ORIGIN_AND_DESTINATION)
      .to(PricingZoneFindByOriginAndDestination)
      .inSingletonScope();

    //Commodity type
    container
      .bind<FindAllCommodityTypes>(TYPES.COMMODITY_TYPE_FIND_ALL)
      .to(FindAllCommodityTypes)
      .inSingletonScope();
    container
      .bind<FindCommodityTypeByStatus>(TYPES.COMMODITY_TYPE_FIND_BY_STATUS)
      .to(FindCommodityTypeByStatus)
      .inSingletonScope();
    container
      .bind<CommodityTypeRepository>(TYPES.COMMODITY_TYPE_REPOSITORY)
      .to(CommodityTypeService)
      .inSingletonScope();
    container
      .bind<UpdateCommodityType>(TYPES.COMMODITY_TYPE_UPDATE)
      .to(UpdateCommodityType)
      .inSingletonScope();
    container
      .bind<CreateCommodityType>(TYPES.COMMODITY_TYPE_SAVE)
      .to(CreateCommodityType)
      .inSingletonScope();
    container
      .bind<DeleteCommodityType>(TYPES.COMMODITY_TYPE_DELETE)
      .to(DeleteCommodityType)
      .inSingletonScope();

    //COURIER ORDER
    container
      .bind<CourierOrderRepository>(TYPES.COURIER_ORDER_REPOSITORY)
      .to(CourierOrderServices)
      .inSingletonScope();
    container
      .bind<CalculatePricesCourierOrder>(TYPES.COURIER_ORDER_CALCULATE_PRICING)
      .to(CalculatePricesCourierOrder)
      .inSingletonScope();

    //Carlos
    //Ports
    container
      .bind<SearchPortsByDescriptionLike>(TYPES.SEARCH_PORTS_BY_DESCRIPTION_LIKE)
      .to(SearchPortsByDescriptionLike)
      .inSingletonScope();
    container
      .bind<PortRepository>(TYPES.PORT_REPOSITORY)
      .to(PortService)
      .inSingletonScope();
    //invoice
    container
      .bind<InvoiceUpdate>(TYPES.UPDATE_INVOICE)
      .to(InvoiceUpdate)
      .inSingletonScope();
    container
      .bind<InvoiceCreate>(TYPES.CREATE_INVOICE)
      .to(InvoiceCreate)
      .inSingletonScope();
    container
      .bind<CreateInvoiceFromOrder>(TYPES.CREATE_INVOICE_ORDER)
      .to(CreateInvoiceFromOrder)
      .inSingletonScope();
    container
      .bind<InvoiceFindAll>(TYPES.FINDALL_INVOICE)
      .to(InvoiceFindAll)
      .inSingletonScope();
    container
      .bind<InvoiceFindByDateRange>(TYPES.FINDBYDATERANGE_INVOICE)
      .to(InvoiceFindByDateRange)
      .inSingletonScope();
    container
      .bind<InvoiceFindByDateRangeAndCustomer>(TYPES.FINDBYDATERANGEANDCUSTOMER_INVOICE)
      .to(InvoiceFindByDateRangeAndCustomer)
      .inSingletonScope();
    container
      .bind<InvoiceFindByPk>(TYPES.FINDBYPK_INVOICE)
      .to(InvoiceFindByPk)
      .inSingletonScope();
    container
      .bind<InvoiceFindByClientId>(TYPES.FINDBYCLIENTID_INVOICE)
      .to(InvoiceFindByClientId)
      .inSingletonScope();
    container
      .bind<InvoiceRepository>(TYPES.INVOICE_REPOSITORY)
      .to(InvoiceService)
      .inSingletonScope();
    container
      .bind<PurchaseRepository>(TYPES.PURCHASE_REPOSITORY)
      .to(PurchaseServices)
      .inSingletonScope();
    container
      .bind<PurchaseScraping>(TYPES.PURCHASE_SCRAPING)
      .to(PurchaseScraping)
      .inSingletonScope();
    container
      .bind<RoleRepository>(TYPES.ROLE_REPOSITORY)
      .to(RoleServices)
      .inSingletonScope();
    container
      .bind<RoleFindByCompanyId>(TYPES.FIND_ROLE_BY_COMPANY_ID)
      .to(RoleFindByCompanyId)
      .inSingletonScope();
    container
      .bind<CreateRole>(TYPES.CREATE_ROLE)
      .to(CreateRole)
      .inSingletonScope();
    container
      .bind<UpdateRole>(TYPES.UPDATE_ROLE)
      .to(UpdateRole)
      .inSingletonScope();
    container
      .bind<RoleFindAll>(TYPES.FINDALL_ROLE)
      .to(RoleFindAll)
      .inSingletonScope();
    container
      .bind<RoleFindById>(TYPES.FINDBYMENU_ROLE)
      .to(RoleFindById)
      .inSingletonScope();
    container
      .bind<RoleFindByIdNoUsers>(TYPES.ROLE_FIND_BY_ID_NO_USERS)
      .to(RoleFindByIdNoUsers)
      .inSingletonScope();
    container
      .bind<RoleFindMenuPlaneByRoleId>(TYPES.FINDMENUPLANEBYROLEID_ROLE)
      .to(RoleFindMenuPlaneByRoleId)
      .inSingletonScope();
    container
      .bind<RoleFindMenuPlaneByRoleName>(TYPES.FINDMENUPLANEBYROLENAME_ROLE)
      .to(RoleFindMenuPlaneByRoleName)
      .inSingletonScope();
    //pallets factor
    container
      .bind<PalletsFactorUpdate>(TYPES.UPDATE_PALLETSFACTOR)
      .to(PalletsFactorUpdate)
      .inSingletonScope();
    container
      .bind<PalletsFactorCreate>(TYPES.CREATE_PALLETSFACTOR)
      .to(PalletsFactorCreate)
      .inSingletonScope();
    container
      .bind<PalletsFactorFindAll>(TYPES.FINDALL_PALLETSFACTOR)
      .to(PalletsFactorFindAll)
      .inSingletonScope();
    container
      .bind<PalletsFactorRepository>(TYPES.PALLETSFACTOR_REPOSITORY)
      .to(PalletsFactorService)
      .inSingletonScope();
    container
      .bind<PriceListUpdateVolume>(TYPES.UPDATE_PRICE_LIST_VOLUME)
      .to(PriceListUpdateVolume)
      .inSingletonScope();
    container
      .bind<PriceListFindAllByStatusVolume>(TYPES.FINDBYSTATUS_PRICE_LIST_VOLUME)
      .to(PriceListFindAllByStatusVolume)
      .inSingletonScope();
    container
      .bind<PriceListCreateVolume>(TYPES.CREATE_PRICE_LIST_VOLUME)
      .to(PriceListCreateVolume)
      .inSingletonScope();
    container
      .bind<PriceListFindAllVolume>(TYPES.FINDALL_PRICE_LIST_VOLUME)
      .to(PriceListFindAllVolume)
      .inSingletonScope();
    container
      .bind<PriceListRepositoryVolume>(TYPES.PRICE_LIST_REPOSITORY_VOLUME)
      .to(PriceListVolumeServices)
      .inSingletonScope();
    container
      .bind<PriceListFindByOriginAndDestination>(TYPES.FINDBY_ORIGINANDDESTINATION_VOLUME)
      .to(PriceListFindByOriginAndDestination)
      .inSingletonScope();

    //price list weight
    container
      .bind<PriceListFindByOriginAndDestinationWeight>(TYPES.FINDBY_ORIGINANDDESTINATION_WEIGHT)
      .to(PriceListFindByOriginAndDestinationWeight)
      .inSingletonScope();
    container
      .bind<PriceListUpdateWeight>(TYPES.UPDATE_PRICE_LIST_WEIGHT)
      .to(PriceListUpdateWeight)
      .inSingletonScope();
    container
      .bind<PriceListFindAllByStatusWeight>(TYPES.FINDBYSTATUS_PRICE_LIST_WEIGHT)
      .to(PriceListFindAllByStatusWeight)
      .inSingletonScope();
    container
      .bind<PriceListCreateWeight>(TYPES.CREATE_PRICE_LIST_WEIGHT)
      .to(PriceListCreateWeight)
      .inSingletonScope();
    container
      .bind<PriceListFindAllWeight>(TYPES.FINDALL_PRICE_LIST_WEIGHT)
      .to(PriceListFindAllWeight)
      .inSingletonScope();

    container
      .bind<PriceListRepositoryWeight>(TYPES.PRICE_LIST_REPOSITORY_WEIGHT)
      .to(PriceListWeightServices)
      .inSingletonScope();
    //Andres Escorcia
    //Taxe
    container
      .bind<TaxeUpdate>(TYPES.UPDATE_TAXE)
      .to(TaxeUpdate)
      .inSingletonScope();
    container
      .bind<TaxeFindByStatus>(TYPES.FINDBYSTATUS_TAXE)
      .to(TaxeFindByStatus)
      .inSingletonScope();
    container
      .bind<TaxeCreate>(TYPES.CREATE_TAXE)
      .to(TaxeCreate)
      .inSingletonScope();
    container
      .bind<TaxeFindAll>(TYPES.FINDALL_TAXE)
      .to(TaxeFindAll)
      .inSingletonScope();
    container
      .bind<TaxeRepository>(TYPES.TAXE_REPOSITORY)
      .to(TaxeServices)
      .inSingletonScope();
    //File
    container
      .bind<FindFile>(TYPES.FIND_FILE_BY_NAME)
      .to(FindFile)
      .inSingletonScope();
    container
      .bind<CreateFile>(TYPES.CREATE_FILE)
      .to(CreateFile)
      .inSingletonScope();
    container
      .bind<FileFindAll>(TYPES.FINDALL_FILE)
      .to(FileFindAll)
      .inSingletonScope();
    container
      .bind<FileRepository>(TYPES.FILE_REPOSITORY)
      .to(FileServices)
      .inSingletonScope();
    container
      .bind<CompanyFindById>(TYPES.FINDBYID_COMPANY)
      .to(CompanyFindById)
      .inSingletonScope();
    container
      .bind<CompanyFindByIdPublic>(TYPES.FINDBYID_COMPANY_PUBLIC)
      .to(CompanyFindByIdPublic)
      .inSingletonScope();
    container
      .bind<CompanyUpdate>(TYPES.UPDATE_COMPANY)
      .to(CompanyUpdate)
      .inSingletonScope();
    container
      .bind<CreateCompany>(TYPES.CREATE_COMPANY)
      .to(CreateCompany)
      .inSingletonScope();
    container
      .bind<CompanyFindAll>(TYPES.FINDALL_COMPANY)
      .to(CompanyFindAll)
      .inSingletonScope();
    container
      .bind<CompanyRepository>(TYPES.COMPANY_REPOSITORY)
      .to(CompanyServices)
      .inSingletonScope();
    container
      .bind<CompanyRepositoryPublic>(TYPES.COMPANY_REPOSITORY_PUBLIC)
      .to(CompanyServicesPublic)
      .inSingletonScope();
    //Warehouses
    container
      .bind<WarehouseFindByStatus>(TYPES.FINDBYSTATUS_WAREHOUSE)
      .to(WarehouseFindByStatus)
      .inSingletonScope();
    container
      .bind<WarehouseFindByCode>(TYPES.FINDBYCODE_WAREHOUSE)
      .to(WarehouseFindByCode)
      .inSingletonScope();
    container
      .bind<WarehouseUpdate>(TYPES.UPDATE_WAREHOUSE)
      .to(WarehouseUpdate)
      .inSingletonScope();
    container
      .bind<CreateWarehouse>(TYPES.CREATE_WAREHOUSE)
      .to(CreateWarehouse)
      .inSingletonScope();
    container
      .bind<WarehouseFindAll>(TYPES.FINDALL_WAREHOUSE)
      .to(WarehouseFindAll)
      .inSingletonScope();
    container
      .bind<WarehouseRepository>(TYPES.WAREHOUSE_REPOSITORY)
      .to(WarehouseServices)
      .inSingletonScope();
    //TIMELINECONFIG
    container
      .bind<CreateTimelineConfig>(TYPES.TIMELINECONFIG_TYPE_SAVE)
      .to(CreateTimelineConfig)
      .inSingletonScope();
    container
      .bind<TimelineConfigRepository>(TYPES.TIMELINECONFIG_TYPE_REPOSITORY)
      .to(TimelineConfigServices)
      .inSingletonScope();
    container
      .bind<FindAllTimelineConfig>(TYPES.TIMELINECONFIG_TYPE_FINDALL)
      .to(FindAllTimelineConfig)
      .inSingletonScope();
    container
      .bind<FindAllTimelineConfigByStatus>(TYPES.TIMELINECONFIG_TYPE_FINDALL_BY_STATUS)
      .to(FindAllTimelineConfigByStatus)
      .inSingletonScope();
    //Routes
    container
      .bind<RoutesFindByStatus>(TYPES.FINDBYSTATUS_ROUTES)
      .to(RoutesFindByStatus)
      .inSingletonScope();
    container
      .bind<RouteFindById>(TYPES.FINDBYID_ROUTES)
      .to(RouteFindById)
      .inSingletonScope();
    container
      .bind<RoutesFindByOiriginAndDestination>(TYPES.ROUTES_FIND_BY_ORIGIN_AND_DESTINATION)
      .to(RoutesFindByOiriginAndDestination)
      .inSingletonScope();
    container
      .bind<RoutesUpdate>(TYPES.UPDATE_ROUTES)
      .to(RoutesUpdate)
      .inSingletonScope();
    container
      .bind<CreateRoutes>(TYPES.CREATE_ROUTES)
      .to(CreateRoutes)
      .inSingletonScope();
    container
      .bind<OrderFindAllByModule>(TYPES.ORDER_FIND_ALL_BY_MODULE)
      .to(OrderFindAllByModule)
      .inSingletonScope();
    container
      .bind<OrderFindByClientId>(TYPES.ORDER_FIND_BY_CLIENT_ID)
      .to(OrderFindByClientId)
      .inSingletonScope();
    container
      .bind<OrderFindBySerial>(TYPES.ORDER_FIND_BY_SERIAL)
      .to(OrderFindBySerial)
      .inSingletonScope();
    container
      .bind<RoutesFindAll>(TYPES.FINDALL_ROUTES)
      .to(RoutesFindAll)
      .inSingletonScope();
    container
      .bind<RoutesRepository>(TYPES.ROUTES_REPOSITORY)
      .to(RoutesServices)
      .inSingletonScope();
    //CurrencyFactor
    container
      .bind<CurrencyFactorFindRateByDate>(TYPES.FINDRATEBYDATE_CURRENCY_FACTOR)
      .to(CurrencyFactorFindRateByDate)
      .inSingletonScope();
    container
      .bind<CurrencyFactorFindByCurrencyCode>(TYPES.FINDBYCURRENCYCODE_CURRENCYFACTOR)
      .to(CurrencyFactorFindByCurrencyCode)
      .inSingletonScope();
    container
      .bind<CurrencyFactorUpdate>(TYPES.UPDATE_CURRENCYFACTOR)
      .to(CurrencyFactorUpdate)
      .inSingletonScope();
    container
      .bind<CreateCurrencyFactor>(TYPES.CREATE_CURRENCYFACTOR)
      .to(CreateCurrencyFactor)
      .inSingletonScope();
    container
      .bind<CurrencyFactorFindAll>(TYPES.FINDALL_CURRENCYFACTOR)
      .to(CurrencyFactorFindAll)
      .inSingletonScope();
    container
      .bind<CurrencyFactorRepository>(TYPES.CURRENCYFACTOR_REPOSITORY)
      .to(CurrencyFactorServices)
      .inSingletonScope();
    //Currency
    container
      .bind<CurrencyFindById>(TYPES.FINDBYID_CURRENCY)
      .to(CurrencyFindById)
      .inSingletonScope();
    container
      .bind<CurrencyUpdate>(TYPES.UPDATE_CURRENCY)
      .to(CurrencyUpdate)
      .inSingletonScope();
    container
      .bind<CreateCurrency>(TYPES.CREATE_CURRENCY)
      .to(CreateCurrency)
      .inSingletonScope();
    container
      .bind<CurrencyFindAll>(TYPES.FINDALL_CURRENCY)
      .to(CurrencyFindAll)
      .inSingletonScope();
    container
      .bind<CurrencyRepository>(TYPES.CURRENCY_REPOSITORY)
      .to(CurrencyServices)
      .inSingletonScope();
    //Zone
    container
      .bind<ZoneUpdate>(TYPES.UPDATE_ZONE)
      .to(ZoneUpdate)
      .inSingletonScope();
    container
      .bind<CreateZone>(TYPES.CREATE_ZONE)
      .to(CreateZone)
      .inSingletonScope();
    container
      .bind<ZoneFindByStatusAndCountryAndState>(TYPES.FINDSTATUSCOUNTRYSTATE_ZONE)
      .to(ZoneFindByStatusAndCountryAndState)
      .inSingletonScope();
    container
      .bind<ZoneFindAll>(TYPES.FINDALL_ZONE)
      .to(ZoneFindAll)
      .inSingletonScope();
    container
      .bind<ZoneFindByDescriptionLike>(TYPES.FIND_BY_DESCRIPTION_LIKE_ZONE)
      .to(ZoneFindByDescriptionLike)
      .inSingletonScope();
    container
      .bind<ZoneRepository>(TYPES.ZONE_REPOSITORY)
      .to(ZoneServices)
      .inSingletonScope();

    //TypeIdentification
    container
      .bind<UpdateTypeIdentification>(TYPES.UPDATE_TYPE_IDENTIFICATION)
      .to(UpdateTypeIdentification)
      .inSingletonScope();
    container
      .bind<CreateTypeIdentification>(TYPES.CREATE_TYPE_IDENTIFICATION)
      .to(CreateTypeIdentification)
      .inSingletonScope();
    container
      .bind<TypeIdentificationFindAll>(TYPES.FINDALL_TYPE_IDENTIFICATION)
      .to(TypeIdentificationFindAll)
      .inSingletonScope();

    container
      .bind<TypeIdentificationRepository>(TYPES.TYPE_IDENTIFICATION_REPOSITORY)
      .to(TypeIdentificationService)
      .inSingletonScope();
    //State
    container
      .bind<StateUpdate>(TYPES.UPDATE_STATE)
      .to(StateUpdate)
      .inSingletonScope();
    container
      .bind<StateFindByCountryCode>(TYPES.FINDBYCOUNTRYCODE_STATE)
      .to(StateFindByCountryCode)
      .inSingletonScope();
    container
      .bind<StateFindByStatus>(TYPES.FINDBYSTATUS_STATE)
      .to(StateFindByStatus)
      .inSingletonScope();
    container
      .bind<CreateState>(TYPES.CREATE_STATE)
      .to(CreateState)
      .inSingletonScope();
    container
      .bind<StateFindAll>(TYPES.FINDALL_STATE)
      .to(StateFindAll)
      .inSingletonScope();
    container
      .bind<StateFindByDescriptionLike>(TYPES.FIND_BY_DESCRIPTION_LIKE_STATE)
      .to(StateFindByDescriptionLike)
      .inSingletonScope();
    container
      .bind<StateRepository>(TYPES.STATE_REPOSITORY)
      .to(StateServices)
      .inSingletonScope();
    //Country
    container
      .bind<CountryUpdate>(TYPES.UPDATE_COUNTRY)
      .to(CountryUpdate)
      .inSingletonScope();
    container
      .bind<CountryFindByStatus>(TYPES.FINDBYSTATUS_COUNTRY)
      .to(CountryFindByStatus)
      .inSingletonScope();
    container
      .bind<CreateCountry>(TYPES.CREATE_COUNTRY)
      .to(CreateCountry)
      .inSingletonScope();
    container
      .bind<CountryFindAll>(TYPES.FINDALL_COUNTRY)
      .to(CountryFindAll)
      .inSingletonScope();
    container
      .bind<CountryFindByDescriptionLike>(TYPES.FIND_BY_DESCRIPTION_LIKE_COUNTRY)
      .to(CountryFindByDescriptionLike)
      .inSingletonScope();
    container
      .bind<CountryFindByDescriptionLikeInGeneralTerritories>(TYPES.COUNTRY_FIND_BY_DESCRIPTION_IN_GENERAL_TERRITORIES)
      .to(CountryFindByDescriptionLikeInGeneralTerritories)
      .inSingletonScope();
    container
      .bind<CountryRepository>(TYPES.TERRITORIES_REPOSITORY)
      .to(CountryServices)
      .inSingletonScope();
    //Dony Cortinez
    container
      .bind<ConsecutiveFindByWarehouseAndType>(TYPES.FINDBYWAREHOUSEANDTYPE_CONSECUTIVE)
      .to(ConsecutiveFindByWarehouseAndType)
      .inSingletonScope();
    container
      .bind<ConsecutiveRepository>(TYPES.CONSECUTIVE_REPOSITORY)
      .to(ConsecutiveServices)
      .inSingletonScope();
    container
      .bind<CreateOrder>(TYPES.CREATE_ORDER)
      .to(CreateOrder)
      .inSingletonScope();
    container
      .bind<UpdateOrder>(TYPES.UPDATE_ORDER)
      .to(UpdateOrder)
      .inSingletonScope();
    container
      .bind<OrderFindByPk>(TYPES.FINDBYPK_ORDER)
      .to(OrderFindByPk)
      .inSingletonScope();
    container
      .bind<OrderFindByDateRange>(TYPES.FINDBYDATERANGE_ORDER)
      .to(OrderFindByDateRange)
      .inSingletonScope();
    container
      .bind<OrderFindByDateRangeAndCustomerId>(TYPES.FINDBYDATERANGEANDCUSTOMERID_ORDER)
      .to(OrderFindByDateRangeAndCustomerId)
      .inSingletonScope();
    container
      .bind<OrderRepository>(TYPES.ORDER_REPOSITORY)
      .to(OrderServices)
      .inSingletonScope();
    container
      .bind<UpdateCustomerPassword>(TYPES.UPDATE_CUSTOMER_PASSWORD)
      .to(UpdateCustomerPassword)
      .inSingletonScope();
    container
      .bind<CustomerGetSecurityCode>(TYPES.GET_SECURITYCODE_CUSTOMER)
      .to(CustomerGetSecurityCode)
      .inSingletonScope();
    container
      .bind<CustomerFindById>(TYPES.FINDBYID_CUSTOMER)
      .to(CustomerFindById)
      .inSingletonScope();
    container
      .bind<CreateCustomer>(TYPES.CREATE_CUSTOMER)
      .to(CreateCustomer)
      .inSingletonScope();
    container
      .bind<CustomerRepository>(TYPES.CUSTOMER_REPOSITORY)
      .to(CustomerServices)
      .inSingletonScope();
    container
      .bind<UpdateUserPassword>(TYPES.UPDATE_USER_PASSWORD)
      .to(UpdateUserPassword)
      .inSingletonScope();
    container
      .bind<CheckUserExistence>(TYPES.CHECK_USER_EXISTENCE)
      .to(CheckUserExistence)
      .inSingletonScope();
    container
      .bind<UserGetSecurityCode>(TYPES.GET_SECURITYCODE_USER)
      .to(UserGetSecurityCode)
      .inSingletonScope();
    container
      .bind<UserFindById>(TYPES.FINDBYID_USER)
      .to(UserFindById)
      .inSingletonScope();
    container
      .bind<CreateUser>(TYPES.CREATE_USER)
      .to(CreateUser)
      .inSingletonScope();
    container
      .bind<UserRepository>(TYPES.USER_REPOSITORY)
      .to(UserServices)
      .inSingletonScope();
    container
      .bind<SwitchesFindAll>(TYPES.FINDALL_SWITCH)
      .to(SwitchesFindAll)
      .inSingletonScope();
    container
      .bind<SwitchesRepository>(TYPES.SWITCH_REPOSITORY)
      .to(SwitchesServices)
      .inSingletonScope();
    container
      .bind<OperationFindAll>(TYPES.FINDALL_TYPE_OPERATION)
      .to(OperationFindAll)
      .inSingletonScope();
    container
      .bind<OperationFindByProcessType>(TYPES.FINDBYPROCESSTYPE_TYPE_OPERATION)
      .to(OperationFindByProcessType)
      .inSingletonScope();
    container
      .bind<UpdateOperation>(TYPES.UPDATE_OPERATION)
      .to(UpdateOperation)
      .inSingletonScope();
    container
      .bind<CreateOperation>(TYPES.CREATE_OPERATION)
      .to(CreateOperation)
      .inSingletonScope();
    container
      .bind<OperationRepository>(TYPES.TYPE_OPERATION_REPOSITORY)
      .to(OperationServices)
      .inSingletonScope();
    container
      .bind<LoginFindToken>(TYPES.LOGIN_USER)
      .to(LoginFindToken)
      .inSingletonScope();
    container
      .bind<LoginRepository>(TYPES.LOGIN_REPOSITORY)
      .to(LoginService)
      .inSingletonScope();
    container
      .bind<NoveltyRepository>(TYPES.NOVELTY_REPOSITORY)
      .to(NoveltyServices)
      .inSingletonScope();
    container
      .bind<CreateActivities>(TYPES.CREATE_ACTIVITIES)
      .to(CreateActivities)
      .inSingletonScope();
    container
      .bind<ActivitiesRepository>(TYPES.ACTIVITIES_REPOSITORY)
      .to(ActivitiesServices)
      .inSingletonScope();
    container
      .bind<EventsFindAll>(TYPES.FINDALL_EVENTS)
      .to(EventsFindAll)
      .inSingletonScope();
    container
      .bind<EventsFindAllConfActive>(TYPES.FINDALL_CONF_ACTIVE_EVENTS)
      .to(EventsFindAllConfActive)
      .inSingletonScope();
    container
      .bind<EventsFindByProcessType>(TYPES.FINDEVENTSBYPROCESSTYPE_EVENTS)
      .to(EventsFindByProcessType)
      .inSingletonScope();
    container
      .bind<EventsFindAllByTimelineId>(TYPES.FINDALLBYTIMELINEID_EVENTS)
      .to(EventsFindAllByTimelineId)
      .inSingletonScope();
    container
      .bind<UpdateEvents>(TYPES.UPDATE_EVENTS)
      .to(UpdateEvents)
      .inSingletonScope();
    container
      .bind<CreateEvents>(TYPES.CREATE_EVENTS)
      .to(CreateEvents)
      .inSingletonScope();
    container
      .bind<CreateEventsExec>(TYPES.CREATE_EXEC_EVENTS)
      .to(CreateEventsExec)
      .inSingletonScope();
    container
      .bind<EventsRepository>(TYPES.EVENTS_REPOSITORY)
      .to(EventsServices)
      .inSingletonScope();
    container
      .bind<TimelineFindAll>(TYPES.FINDALL_TIMELINE)
      .to(TimelineFindAll)
      .inSingletonScope();
    container
      .bind<TimelineFindByProcessType>(TYPES.FINDBYPROCESSTYPE_TIMELINE)
      .to(TimelineFindByProcessType)
      .inSingletonScope();
    container
      .bind<SwitchFindAllByProcessType>(TYPES.FINDBYPROCESSTYPE_SWITCH)
      .to(SwitchFindAllByProcessType)
      .inSingletonScope();
    container
      .bind<UpdateTimeline>(TYPES.UPDATE_TIMELINE)
      .to(UpdateTimeline)
      .inSingletonScope();
    container
      .bind<CreateTimeline>(TYPES.CREATE_TIMELINE)
      .to(CreateTimeline)
      .inSingletonScope();
    container
      .bind<FindChartValuesByTimeLine>(TYPES.FIND_CHARTVALUESTIMELINE)
      .to(FindChartValuesByTimeLine)
      .inSingletonScope();
    container
      .bind<TimelineRepository>(TYPES.TIMELINE_REPOSITORY)
      .to(TimelineServices)
      .inSingletonScope();
    container
      .bind<ProcessFindAll>(TYPES.FINDALL_PROCESS)
      .to(ProcessFindAll)
      .inSingletonScope();
    container
      .bind<ProcessFindById>(TYPES.FINDBYID_PROCESS)
      .to(ProcessFindById)
      .inSingletonScope();
    container
      .bind<UpdateProcess>(TYPES.UPDATE_PROCESS)
      .to(UpdateProcess)
      .inSingletonScope();
    container
      .bind<CreateProcess>(TYPES.CREATE_PROCESS)
      .to(CreateProcess)
      .inSingletonScope();
    container
      .bind<ProcessRepository>(TYPES.PROCESS_TYPE_REPOSITORY)
      .to(ProcessServices)
      .inSingletonScope();
    container
      .bind<TransportRepository>(TYPES.TYPE_TRANSPORT_REPOSITORY)
      .to(TransportServices)
      .inSingletonScope();
    container
      .bind<TransportFindAll>(TYPES.FINDALL_TRANSPORT)
      .to(TransportFindAll)
      .inSingletonScope();
    container
      .bind<MonetaryTransactionRepository>(TYPES.MONETARY_TRANSACTION_REPOSITORY)
      .to(MonetaryTransactionServices)
      .inSingletonScope();
    container
      .bind<MonetaryTransactionFindAll>(TYPES.FINDALL_MONETARY_TRANSACTION)
      .to(MonetaryTransactionFindAll)
      .inSingletonScope();
    container
      .bind<VolumeRepository>(TYPES.VOLUME_REPOSITORY)
      .to(VolumeServices)
      .inSingletonScope();
    container
      .bind<CreateVolume>(TYPES.CREATE_VOLUME)
      .to(CreateVolume)
      .inSingletonScope();
    container
      .bind<UpdateVolume>(TYPES.UPDATE_VOLUME)
      .to(UpdateVolume)
      .inSingletonScope();
    container
      .bind<VolumeFindAll>(TYPES.FINDALL_VOLUME)
      .to(VolumeFindAll)
      .inSingletonScope();
    container
      .bind<WeightRepository>(TYPES.WEIGHT_REPOSITORY)
      .to(WeightServices)
      .inSingletonScope();
    container
      .bind<CreateWeight>(TYPES.CREATE_WEIGHT)
      .to(CreateWeight)
      .inSingletonScope();
    container
      .bind<UpdateWeight>(TYPES.UPDATE_WEIGHT)
      .to(UpdateWeight)
      .inSingletonScope();
    container
      .bind<WeightFindAll>(TYPES.FINDALL_WEIGHT)
      .to(WeightFindAll)
      .inSingletonScope();
    container
      .bind<UnitOfMeasurementRepository>(TYPES.UNITOFMEASUREMENT_REPOSITORY)
      .to(UnitOfMeasurementServices)
      .inSingletonScope();
    container
      .bind<CreateUnitOfMeasurement>(TYPES.CREATE_UNITOFMEASUREMENT)
      .to(CreateUnitOfMeasurement)
      .inSingletonScope();
    container
      .bind<UpdateUnitOfMeasurement>(TYPES.UPDATE_UNITOFMEASUREMENT)
      .to(UpdateUnitOfMeasurement)
      .inSingletonScope();
    container
      .bind<UnitOfMeasurementFindAll>(TYPES.FINDALL_UNITOFMEASUREMENT)
      .to(UnitOfMeasurementFindAll)
      .inSingletonScope();
    container
      .bind<LoginImageConfigRepository>(TYPES.LOGIN_IMAGE_CONFIG_REPOSITORY)
      .to(LoginImageConfigService)
      .inSingletonScope();
    container
      .bind<FindLoginImageConfigByTypeAndCompany>(TYPES.LOGIN_IMAGE_FIND_BY_TYPE_AND_COMPANY)
      .to(FindLoginImageConfigByTypeAndCompany)
      .inSingletonScope();
    container
      .bind<Runner>(TYPES.RUNNER)
      .to(Runner)
      .inSingletonScope();
    container
      .bind<ExecutorLink>(TYPES.EXECUTOR_LINK)
      .to(ExecutorLink)
      .inSingletonScope();
    container
      .bind<LoggerLink>(TYPES.LOGGER_LINK)
      .to(LoggerLink)
      .inSingletonScope();
    container
      .bind<Logger>(TYPES.LOGGER)
      .to(ConsoleLogger)
      .inSingletonScope();
    container.bind<Window>(TYPES.WINDOW).toConstantValue(window);
    container
      .bind<StateManager>(TYPES.STATE_MANAGER)
      .to(TyStateManager)
      .inSingletonScope();
    container
      .bind<Application>(TYPES.APPLICATION)
      .to(Application)
      .inSingletonScope();
  
    //STOCK
    container
      .bind<StockRepository>(TYPES.STOCK_REPOSITORY)
      .to(StockServices)
      .inSingletonScope();
    container
      .bind<StockFindAll>(TYPES.FINDALL_STOCK)
      .to(StockFindAll)
      .inSingletonScope();
    container
      .bind<FindAllMovements>(TYPES.FIND_STOCK_MOVES)
      .to(FindAllMovements)
      .inSingletonScope();
    container
      .bind<FindReferenceStock>(TYPES.FIND_STOCK_REFERENCE)
      .to(FindReferenceStock)
      .inSingletonScope();
    container
      .bind<FindByDateRangeAndStore>(TYPES.FINDBY_DATERANGE_AND_STORE)
      .to(FindByDateRangeAndStore)
      .inSingletonScope();
    container
      .bind<CalculateAverageCost>(TYPES.CALCULATE_AVERAGE_COST)
      .to(CalculateAverageCost)
      .inSingletonScope();
    container
      .bind<FindFreightStockByDateRangeAndStore>(TYPES.FIND_FREIGHT_STOCK)
      .to(FindFreightStockByDateRangeAndStore)
      .inSingletonScope();
    container
      .bind<FindFreightMovements>(TYPES.FIND_FREIGHT_STOCK_MOVEMENTS)
      .to(FindFreightMovements)
      .inSingletonScope();
      container
      .bind<StoreRepository>(TYPES.STORE_REPOSITORY)
      .to(StoreServices)
      .inSingletonScope();
      container
      .bind<FindAllStore>(TYPES.FIND_ALL_STORE)
      .to(FindAllStore)
      .inSingletonScope();

      container.bind<VueConstructor>(TYPES.VUE).toConstantValue(Vue);
      this._container = container;
  }
  static instance() {
    if (this._instance === null) {
      Container._instance = new Container();
    }
    return this._instance!._container;
  }
}
