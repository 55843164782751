import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { MonetaryTransaction } from '@/tracking/domain/monetarytransaction/MonetaryTransaction';
import { MonetaryTransactionRepository } from '@/tracking/domain/monetarytransaction/MonetaryTransactionRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class MonetaryTransactionServices implements MonetaryTransactionRepository {
  private readonly API_BASE_URI: string = localStorageModule().tracking.host.API_BASE_URI;
  private readonly API_MONETARY_TRANSACTION_FINDALL: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_MONETARY_TRANSACTION_FINDALL;

  async findAll(): Promise<MonetaryTransaction[]> {
    try {
      return await http.get<MonetaryTransaction[]>(this.API_MONETARY_TRANSACTION_FINDALL);
    } catch (err) {
      throw new Error(err);
    }
  }
}
