import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { Order } from '@/courier/domain/order/Order';
import { OrderRepository } from '@/courier/domain/order/OrderRepository';

@Injectable()
export class UpdateOrder extends Command<Promise<any>, Order> {
  public constructor(
    @Inject(TYPES.ORDER_REPOSITORY) private readonly orderRepository: OrderRepository,
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager
  ) {
    super();
  }

  internalExecute(order: Order): Promise<any> {
    return this.orderRepository.update(order);
  }
}
