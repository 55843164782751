import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { httpFetchNoToken } from '@/core/http/HttpFetchWithoutToken';
import { User } from '@/settings/domain/user/User';
import { UserRepository } from '@/settings/domain/user/UserRepository';

@Injectable()
export class UserServices implements UserRepository {
  private readonly API_BASE_URI: string = process.env.VUE_APP_URL_TOKEN;
  private readonly API_USER_SAVE: string = this.API_BASE_URI + localStorageModule().auth.routes.API_USER_SAVE;
  private readonly API_USER_UPDATE: string = this.API_BASE_URI + localStorageModule().auth.routes.API_USER_UPDATE;
  private readonly API_CLIENT_UPDATEPASSWORD: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_CLIENT_UPDATEPASSWORD;
  private readonly API_USER_FINDBYEMAIL: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_USER_FINDBYEMAIL;
  private readonly API_USER_FINDBYID: string = this.API_BASE_URI + localStorageModule().auth.routes.API_USER_FINDBYID;
  private readonly API_CLIENT_GETSECURITYCODE: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_CLIENT_GETSECURITYCODE;
  private readonly API_USER_CHECK_USER_EXISTENCE: string =
    this.API_BASE_URI + localStorageModule().auth.routes.API_VALIDATE_USERNAME;

  async create(user: User) {
    try {
      return await httpFetchNoToken.post<User[]>(this.API_USER_SAVE, JSON.stringify(user));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(user: User) {
    try {
      return await httpFetchNoToken.put<User[]>(this.API_USER_UPDATE, JSON.stringify(user));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async updatePassword(payload: any) {
    try {
      return await httpFetchNoToken.put<User[]>(
        this.API_CLIENT_UPDATEPASSWORD,
        `password=${payload.password}&securityCode=${payload.securityCode}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findById(id: string): Promise<User[]> {
    try {
      return await httpFetchNoToken.get<User[]>(this.API_USER_FINDBYID + id);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async findByEmail(email: string): Promise<User[]> {
    try {
      return await httpFetchNoToken.get<User[]>(this.API_USER_FINDBYEMAIL + email);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async getSecurityCode(email: string): Promise<User[]> {
    try {
      return await httpFetchNoToken.get<User[]>(this.API_CLIENT_GETSECURITYCODE + email);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
  async checkUserExistence(email: string): Promise<boolean> {
    try {
      return await httpFetchNoToken.get<boolean>(this.API_USER_CHECK_USER_EXISTENCE + email);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
