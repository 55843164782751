import { Injectable } from '@/core/di/Injectable';
import router from '@/core/router';
import { Login } from '@/courier/domain/login/Login';
import { LoginRepository } from '@/courier/domain/login/LoginRepository';
import { getRoutesApi } from '@/core/config/ConfigurationServer';
import { getMenu } from '@/core/data/menu';

@Injectable()
export class LoginService implements LoginRepository {
  private user: any = {};

  decodeJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const data = JSON.parse(window.atob(base64));
    this.user = { data };
  }
  async getToken(payload: any): Promise<Login> {
    try {
      const params: any = new URLSearchParams();
      params.append('username', payload.username);
      params.append('password', payload.password);
      params.append('grant_type', 'password');
      const encodedUserPswd = btoa(`${process.env.VUE_APP_BASIC_USERNAME}:${process.env.VUE_APP_BASIC_PASSWORD}`);
      const requestOptions = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          Authorization: 'Basic ' + encodedUserPswd
        },
        body: params
      };
      const response = await fetch(process.env.VUE_APP_URL_TOKEN + '/oauth/token', requestOptions);
      const data = await response.json();
      if (response.ok) {
        const token = data.access_token;
        this.decodeJwt(token);
        const user = this.user['data'].customerName;
        const email = this.user['data'].email;
        const expiration: any = this.user['data'].exp * 1000;
        const urlApi: any = this.user['data'].company_resources;
        const configProfile: any = this.user['data'].configProfile;
        const rolNAme: any = this.user['data'].authorities;
        const logoUser: any = this.user['data'].logo;
        const businessId: any = this.user['data'].businessId;
        const consAutoReference: any = this.user['data'].consAutoReference;
        localStorage.removeItem('dataCompany');
        localStorage.removeItem('dataModuleCompany');
        await getMenu(rolNAme);
        await getRoutesApi(urlApi, configProfile);
        localStorage.setItem('operationalProfiles', JSON.stringify(data.company_resources));
        localStorage.setItem('consAutoReference', JSON.stringify(consAutoReference));
        localStorage.setItem('accessTokenCompany', token);
        localStorage.setItem('customerId', data.customerId);
        localStorage.setItem('customerCode', data.customerCode);
        localStorage.setItem('userNameCompany', user);
        localStorage.setItem('userEmailCompany', email);
        localStorage.setItem('dateExpirationCompany', expiration);
        localStorage.setItem('userRol', rolNAme);
        localStorage.setItem('logoUser', logoUser);
        localStorage.setItem('businessId', businessId);
        router.replace({ name: 'OrderHistory' });
      } else {
        localStorage.removeItem('consAutoReference');
        localStorage.removeItem('accessTokenCompany');
        localStorage.removeItem('customerId');
        localStorage.removeItem('customerCode');
        localStorage.removeItem('userNameCompany');
        localStorage.removeItem('userEmailCompany');
        localStorage.removeItem('dateExpirationCompany');
        localStorage.removeItem('dataModuleCompany');
        localStorage.removeItem('userRol');
        localStorage.removeItem('logoUser');
        localStorage.removeItem('businessId');
      }
      return data;
    } catch (error) {
      localStorage.removeItem('consAutoReference');
      localStorage.removeItem('accessTokenCompany');
      localStorage.removeItem('customerId');
      localStorage.removeItem('customerCode');
      localStorage.removeItem('userNameCompany');
      localStorage.removeItem('userEmailCompany');
      localStorage.removeItem('dateExpirationCompany');
      localStorage.removeItem('dataModuleCompany');
      localStorage.removeItem('userRol');
      localStorage.removeItem('logoUser');
      localStorage.removeItem('businessId');
      throw new Error(`${error}`);
    }
  }
}
