import { httpFetchNoToken } from '@/core/http/HttpFetchWithoutToken';
import { Injectable } from '@/core/di/Injectable';
import { CompanyRepositoryPublic } from '@/settings/domain/company/CompanyRepositoryPublic';
import { Company } from '@/settings/domain/company/Company';

@Injectable()
export class CompanyServicesPublic implements CompanyRepositoryPublic {
  private readonly API_BASE_URI: string = process.env.VUE_APP_URL_TOKEN;

  async findCompanyByIdPublic(companyId: string): Promise<Company[]> {
    try {
      return await httpFetchNoToken.get<Company[]>(this.API_BASE_URI + `/setting/findById/${companyId}`);
    } catch (err) {
      throw new Error(`${err}`);
    }
  }
}
