
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'app'
})
export default class App extends Vue {
  show = true;
  mounted() {
    this.$nextTick(() => this.timeOut);
  }
  get timeOut() {
    return setTimeout(() => {
      this.show = !this.show;
    }, 1000);
  }
}
