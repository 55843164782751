import { BaseLink } from './BaseLink';
import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Logger } from '@/core/helper/Logger';

@Injectable()
export class LoggerLink extends BaseLink {
  constructor(@Inject(TYPES.LOGGER) private readonly logger: Logger) {
    super();
  }

  next(): void {
    //TODO: without code
  }
}
