import { requestGet } from '@/core/plugins/FunctionUtil';

export async function getMenu(rol: number) {
  try {
    await requestGet('role/findMenuByRoleName/' + rol)
      .then((response: any) => response.json())
      .then((data: any) => {
        localStorage.setItem('menuUser', JSON.stringify(data['menu']));
      });
  } catch (err) {
    throw new Error(err);
  }
}
