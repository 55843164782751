import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { CourierOrderRepository } from '@/courier/domain/CourierOrder/CourierOrderRepository';
import { CourierOrder } from '@/courier/domain/CourierOrder/CourierOrder';

@Injectable()
export class CourierOrderServices implements CourierOrderRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_PRICE_LIST_CALCULATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PRICE_LIST_CALCULATE;

  async calculatePricing(payload: any) {
    try {
      return await http.post<CourierOrder>(this.API_PRICE_LIST_CALCULATE, JSON.stringify(payload));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
