import { Injectable } from '@/core/di/Injectable';
import { TaxeRepository } from '@/settings/domain/taxe/TaxeRepository';
import { Taxe } from '@/settings/domain/taxe/Taxe';
import { http } from '@/core/http/HttpFetch';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class TaxeServices implements TaxeRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_TAXE_FINDALL: string = this.API_BASE_URI + localStorageModule().config.routes.API_TAXE_FINDALL;
  private readonly API_TAXE_FINDALL_BY_STATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_TAXE_FINDALL_BY_STATUS;
  private readonly API_TAXE_SAVE: string = this.API_BASE_URI + localStorageModule().config.routes.API_TAXE_SAVE;
  private readonly API_TAXE_UPDATE: string = this.API_BASE_URI + localStorageModule().config.routes.API_TAXE_UPDATE;

  async create(taxe: Taxe) {
    try {
      return await http.post<Taxe[]>(this.API_TAXE_SAVE, JSON.stringify(taxe));
    } catch (error) {
      throw new Error(error);
    }
  }
  async update(taxe: Taxe) {
    try {
      return await http.put<Taxe[]>(this.API_TAXE_UPDATE, JSON.stringify(taxe));
    } catch (error) {
      throw new Error(error);
    }
  }
  async findTaxeByStatus(status: boolean): Promise<Taxe[]> {
    try {
      return await http.get<Taxe[]>(this.API_TAXE_FINDALL_BY_STATUS + status);
    } catch (err) {
      throw new Error(err);
    }
  }
  async findAll(): Promise<Taxe[]> {
    try {
      return await http.get<Taxe[]>(this.API_TAXE_FINDALL);
    } catch (err) {
      throw new Error(err);
    }
  }
}
