import { TYPES } from '@/core/config/Types';
import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { StateManager } from '@/core/statemanager/StateManager';
import { Login } from '@/courier/domain/login/Login';
import { LoginRepository } from '@/courier/domain/login/LoginRepository';

@Injectable()
export class LoginFindToken extends Query<Promise<Login>, string> {
  public constructor(
    @Inject(TYPES.LOGIN_REPOSITORY)
    private readonly loginRepository: LoginRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }

  async internalExecute(params: any): Promise<Login> {
    this.stateManager.state.token = await this.loginRepository.getToken(params);
    return this.stateManager.state.token;
  }
}
