import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Query } from '@/core/cqrs/Query';
import { Company } from '@/settings/domain/company/Company';
import { CompanyRepository } from '@/settings/domain/company/CompanyRepository';

@Injectable()
export class CompanyFindById extends Query<Promise<Company[]>, string> {
  public constructor(
    @Inject(TYPES.COMPANY_REPOSITORY)
    private readonly companyRepository: CompanyRepository,
    @Inject(TYPES.STATE_MANAGER)
    private readonly stateManager: StateManager
  ) {
    super();
  }
  async internalExecute(companyId: string): Promise<Company[]> {
    const dataCompany = await this.companyRepository.findCompanyById(companyId);
    this.stateManager.patch({ dataCompany });
    return this.stateManager.state.dataCompany;
  }
}
