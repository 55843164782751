import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { Currency } from '@/settings/domain/currency/Currency';
import { CurrencyRepository } from '@/settings/domain/currency/CurrencyRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class CurrencyServices implements CurrencyRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_CURRENCY_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_CURRENCY_FINDALL;
  private readonly API_CURRENCY_SAVE: string = this.API_BASE_URI + localStorageModule().config.routes.API_CURRENCY_SAVE;
  private readonly API_CURRENCY_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_CURRENCY_UPDATE;
  private readonly API_CURRENCY_FINDALL_BY_ID: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_CURRENCY_FINDALL_BY_ID;
  private readonly API_CURRENCY_FINDALL_BY_COUNTRYCODE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_CURRENCY_UPDATE;

  async create(currency: Currency) {
    try {
      return await http.post<Currency[]>(this.API_CURRENCY_SAVE, JSON.stringify(currency));
    } catch (error) {
      throw new Error(error);
    }
  }
  async update(currency: Currency) {
    try {
      return await http.put<Currency[]>(this.API_CURRENCY_UPDATE, JSON.stringify(currency));
    } catch (error) {
      throw new Error(error);
    }
  }
  async findAll(): Promise<Currency[]> {
    try {
      return await http.get<Currency[]>(this.API_CURRENCY_FINDALL);
    } catch (err) {
      throw new Error(err);
    }
  }
  async findCurrencyById(currencyId: string): Promise<Currency[]> {
    try {
      return await http.get<Currency[]>(this.API_CURRENCY_FINDALL_BY_ID + currencyId);
    } catch (err) {
      throw new Error(err);
    }
  }
  async findCurrencyByCountryCode(countryCode: string): Promise<Currency[]> {
    try {
      return await http.get<Currency[]>(this.API_CURRENCY_FINDALL_BY_COUNTRYCODE + countryCode);
    } catch (err) {
      throw new Error(err);
    }
  }
}
