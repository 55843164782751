import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { States } from '@/settings/domain/state/States';
import { StateRepository } from '@/settings/domain/state/StateRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class StateServices implements StateRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_STATES_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_STATES_FINDALL;
  private readonly API_STATES_FINDALL_BY_STATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_STATES_FINDALL_BY_STATUS;
  private readonly API_STATES_SAVE: string = this.API_BASE_URI + localStorageModule().config.routes.API_STATES_SAVE;
  private readonly API_STATES_UPDATE: string = this.API_BASE_URI + localStorageModule().config.routes.API_STATES_UPDATE;
  private readonly API_STATES_FINDALL_BY_COUNTRYCODE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_STATES_FINDALL_BY_COUNTRYCODE;
  private readonly API_STATES_FINDALL_BY_DESCRIPTION_LIKE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_STATES_FINDALL_BY_DESCRIPTION_LIKE;

  async create(state: States) {
    try {
      return await http.post<States[]>(this.API_STATES_SAVE, JSON.stringify(state));
    } catch (error) {
      throw new Error(error);
    }
  }
  async update(state: States) {
    try {
      return await http.put<States[]>(this.API_STATES_UPDATE, JSON.stringify(state));
    } catch (error) {
      throw new Error(error);
    }
  }
  async findAll(): Promise<States[]> {
    try {
      return await http.get<States[]>(this.API_STATES_FINDALL);
    } catch (err) {
      throw new Error(err);
    }
  }
  async findStateByStatus(payload: any): Promise<States[]> {
    try {
      return await http.get<States[]>(
        this.API_STATES_FINDALL_BY_STATUS + `active=${payload.status}&country=${payload.codeCountry}`
      );
    } catch (err) {
      throw new Error(err);
    }
  }
  async findByDescriptionLike(description: string): Promise<States[]> {
    try {
      return await http.get<States[]>(this.API_STATES_FINDALL_BY_DESCRIPTION_LIKE + description);
    } catch (err) {
      throw new Error(err);
    }
  }
  async findStateByCountryCode(countryCode: string): Promise<States[]> {
    try {
      return await http.get<States[]>(this.API_STATES_FINDALL_BY_COUNTRYCODE + countryCode);
    } catch (err) {
      throw new Error(err);
    }
  }
}
