import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { Process } from '@/tracking/domain/processtype/Process';
import { ProcessRepository } from '@/tracking/domain/processtype/ProcessRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class ProcessServices implements ProcessRepository {
  private readonly API_BASE_URI: string = localStorageModule().tracking.host.API_BASE_URI;
  private readonly API_PROCESS_SAVE: string = this.API_BASE_URI + localStorageModule().tracking.routes.API_PROCESS_SAVE;
  private readonly API_PROCESS_UPDATE: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_PROCESS_UPDATE;
  private readonly API_PROCESS_FINDBYID: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_PROCESS_FINDBYID;
  private readonly API_PROCESS_FINDALL: string =
    this.API_BASE_URI + localStorageModule().tracking.routes.API_PROCESS_FINDALL;

  async create(process: Process) {
    try {
      return await http.post<Process[]>(this.API_PROCESS_SAVE, JSON.stringify(process));
    } catch (error) {
      throw new Error(error);
    }
  }
  async update(process: Process) {
    try {
      return await http.put<Process[]>(this.API_PROCESS_UPDATE, JSON.stringify(process));
    } catch (error) {
      throw new Error(error);
    }
  }
  async findById(params: any): Promise<Process[]> {
    try {
      return await http.get<Process[]>(this.API_PROCESS_FINDBYID + params);
    } catch (err) {
      throw new Error(err);
    }
  }
  async findAll(): Promise<Process[]> {
    try {
      return await http.get<Process[]>(this.API_PROCESS_FINDALL);
    } catch (err) {
      throw new Error(err);
    }
  }
}
