import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { StateManager } from '@/core/statemanager/StateManager';
import { Command } from '@/core/cqrs/Command';
import { Operation } from '@/tracking/domain/typeoperation/Operation';
import { OperationRepository } from '@/tracking/domain/typeoperation/OperationRepository';

@Injectable()
export class CreateOperation extends Command<Promise<any>, Operation> {
  public constructor(
    @Inject(TYPES.STATE_MANAGER) private readonly stateManager: StateManager,
    @Inject(TYPES.TYPE_OPERATION_REPOSITORY)
    private readonly operationRepository: OperationRepository
  ) {
    super();
  }

  internalExecute(operation: Operation): Promise<any> {
    return this.operationRepository.create(operation);
  }
}
