import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { PalletsFactorRepository } from '@/settings/domain/palletsFactor/PalletsFactorRepository';
import { PalletsFactor } from '@/settings/domain/palletsFactor/PalletsFactor';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class PalletsFactorService implements PalletsFactorRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_PALLETSFACTOR_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PALLETSFACTOR_FINDALL;
  private readonly API_PALLETSFACTOR_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PALLETSFACTOR_SAVE;
  private readonly API_PALLETSFACTOR_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PALLETSFACTOR_UPDATE;

  async create(palletsFactor: PalletsFactor) {
    try {
      return await http.post<PalletsFactor[]>(this.API_PALLETSFACTOR_SAVE, JSON.stringify(palletsFactor));
    } catch (error) {
      throw new Error(error);
    }
  }
  async update(palletsFactor: PalletsFactor) {
    try {
      return await http.put<PalletsFactor[]>(this.API_PALLETSFACTOR_UPDATE, JSON.stringify(palletsFactor));
    } catch (error) {
      throw new Error(error);
    }
  }

  async findAll(): Promise<PalletsFactor[]> {
    try {
      return await http.get<PalletsFactor[]>(this.API_PALLETSFACTOR_FINDALL);
    } catch (err) {
      throw new Error(err);
    }
  }
}
