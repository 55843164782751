import { Injectable } from '@/core/di/Injectable';
import { Inject } from '@/core/di/Inject';
import { Query } from '@/core/cqrs/Query';
import { TYPES } from '@/core/config/Types';
import { Role } from '@/settings/domain/role/Role';
import { RoleRepository } from '@/settings/domain/role/RoleRepository';

@Injectable()
export class RoleFindByIdNoUsers extends Query<Promise<Role[]>, number> {
  public constructor(
    @Inject(TYPES.ROLE_REPOSITORY)
    private readonly roleRepository: RoleRepository
  ) {
    super();
  }
  async internalExecute(id: number): Promise<Role[]> {
    return await this.roleRepository.findRoleByIdNoUsers(id);
  }
}
