import { localStorageModule } from '@/core/config/ConfigurationServer';
import { http } from '@/core/http/HttpFetch';
import { Port } from '@/freight/domain/ports/Port';
import { PortRepository } from '@/freight/domain/ports/PortRepository';
import { injectable } from 'inversify-props';

@injectable()
export class PortService implements PortRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_FIND_PORTS_BY_DESCRIPTION_LIKE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_FIND_PORTS_BY_DESCRIPTION_LIKE;
  private readonly API_PORT_FIND_ALL: string = this.API_BASE_URI + localStorageModule().config.routes.API_PORT_FIND_ALL;
  private readonly API_PORT_SAVE: string = this.API_BASE_URI + localStorageModule().config.routes.API_PORT_SAVE;
  private readonly API_PORT_UPDATE: string = this.API_BASE_URI + localStorageModule().config.routes.API_PORT_UPDATE;
  private readonly API_PORT_FIND_ALL_BY_STATUS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_PORT_FIND_ALL_BY_STATUS;

  async create(port: Port) {
    return await http.post<Port>(this.API_PORT_SAVE, JSON.stringify(port)); //Repara
  }
  async update(port: Port) {
    return await http.put<Port>(this.API_PORT_UPDATE, JSON.stringify(port));
  }
  async findPortsByStatus(status: boolean) {
    return await http.get<Port[]>(this.API_PORT_FIND_ALL_BY_STATUS + status);
  }

  async findPortsByDescriptionLike(query: string) {
    return await http.get<Port[]>(this.API_FIND_PORTS_BY_DESCRIPTION_LIKE + query);
  }

  async findAllPorts(): Promise<Port[]> {
    return await http.get<Port[]>(this.API_PORT_FIND_ALL);
  }
}
