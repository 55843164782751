import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { CurrencyFactor } from '@/settings/domain/currencyfactor/CurrencyFactor';
import { CurrencyFactorRepository } from '@/settings/domain/currencyfactor/CurrencyFactorRepository';
import { localStorageModule } from '@/core/config/ConfigurationServer';

@Injectable()
export class CurrencyFactorServices implements CurrencyFactorRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_CURRENCYFACTOR_FINDALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_CURRENCYFACTOR_FINDALL;
  private readonly API_CURRENCYFACTOR_FINDALL_BY_CODE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_CURRENCYFACTOR_FINDALL_BY_CODE;
  private readonly API_CURRENCYFACTOR_FINDALL_BY_DATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_CURRENCYFACTOR_FINDALL_BY_DATE;
  private readonly API_CURRENCYFACTOR_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_CURRENCYFACTOR_SAVE;
  private readonly API_CURRENCYFACTOR_UPDATE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_CURRENCYFACTOR_UPDATE;

  async create(currencyFactor: CurrencyFactor) {
    try {
      return await http.post<CurrencyFactor[]>(this.API_CURRENCYFACTOR_SAVE, JSON.stringify(currencyFactor));
    } catch (error) {
      throw new Error(error);
    }
  }
  async update(currencyFactor: CurrencyFactor) {
    try {
      return await http.put<CurrencyFactor[]>(this.API_CURRENCYFACTOR_UPDATE, JSON.stringify(currencyFactor));
    } catch (error) {
      throw new Error(error);
    }
  }
  async findAll(): Promise<CurrencyFactor[]> {
    try {
      return await http.get<CurrencyFactor[]>(this.API_CURRENCYFACTOR_FINDALL);
    } catch (err) {
      throw new Error(err);
    }
  }
  async findCurrencyFactorByCurrency(currencyFactorCode: string): Promise<CurrencyFactor[]> {
    try {
      return await http.get<CurrencyFactor[]>(this.API_CURRENCYFACTOR_FINDALL_BY_CODE + currencyFactorCode);
    } catch (err) {
      throw new Error(err);
    }
  }
  async findCurrencyFactorRateByDate(payload: any): Promise<CurrencyFactor[]> {
    try {
      return await http.get<CurrencyFactor[]>(
        this.API_CURRENCYFACTOR_FINDALL_BY_DATE + `${payload.currency}&date=${payload.date}`
      );
    } catch (err) {
      throw new Error(err);
    }
  }
}
