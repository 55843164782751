import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Injectable } from '@/core/di/Injectable';
import { http } from '@/core/http/HttpFetch';
import { Containers } from '@/settings/domain/containers/Containers';
import { ContainersRepository } from '@/settings/domain/containers/CotainersRepository';

@Injectable()
export class ContainerService implements ContainersRepository {
  private readonly API_BASE_URI = localStorageModule().config.host.API_BASE_URI;
  private readonly API_CONTAINER_SAVE = this.API_BASE_URI + localStorageModule().config.routes.API_CONTAINER_SAVE;
  private readonly API_CONTAINER_UPDATE = this.API_BASE_URI + localStorageModule().config.routes.API_CONTAINER_UPDATE;
  private readonly API_CONTAINER_FIND_BY_ID =
    this.API_BASE_URI + localStorageModule().config.routes.API_CONTAINER_FIND_BY_ID;
  private readonly API_CONTAINER_FIND_ALL =
    this.API_BASE_URI + localStorageModule().config.routes.API_CONTAINER_FIND_ALL;
  private readonly API_CONTAINER_FIND_ALL_BY_STATUS =
    this.API_BASE_URI + localStorageModule().config.routes.API_CONTAINER_FIND_ALL_BY_STATUS;

  async create(containers: Containers) {
    try {
      return await http.post<Containers>(this.API_CONTAINER_SAVE, JSON.stringify(containers));
    } catch (error) {
      throw new Error(error);
    }
  }
  async update(containers: Containers) {
    try {
      return await http.put<Containers>(this.API_CONTAINER_UPDATE, JSON.stringify(containers));
    } catch (error) {
      throw new Error(error);
    }
  }
  async findContainerById(containerId: string) {
    try {
      return await http.get<Containers>(this.API_CONTAINER_FIND_BY_ID + containerId);
    } catch (error) {
      throw new Error(error);
    }
  }
  async findAll() {
    try {
      return await http.get<Containers[]>(this.API_CONTAINER_FIND_ALL);
    } catch (error) {
      throw new Error(error);
    }
  }
  async findAllByStatus(status: boolean) {
    try {
      return await http.get<Containers[]>(this.API_CONTAINER_FIND_ALL_BY_STATUS + status);
    } catch (error) {
      throw new Error(error);
    }
  }
}
