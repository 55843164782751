import Vue from 'vue';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import {
  required,
  min,
  max,
  email,
  numeric,
  confirmed,
  between,
  // eslint-disable-next-line @typescript-eslint/camelcase
  min_value,
  // eslint-disable-next-line @typescript-eslint/camelcase
  max_value,
  alpha,
  // eslint-disable-next-line @typescript-eslint/camelcase
  alpha_dash,
  // eslint-disable-next-line @typescript-eslint/camelcase
  alpha_num,
  // eslint-disable-next-line @typescript-eslint/camelcase
  alpha_spaces
} from 'vee-validate/dist/rules';
import different from './Different';
import { selectedLocale } from '@/core/plugins/i18n';

extend('required', required);
extend('min', min);
extend('max', max);
extend('email', email);
extend('numeric', numeric);
extend('alpha', alpha);
extend('alpha_dash', alpha_dash);
extend('alpha_num', alpha_num);
extend('between', between);
extend('min_value', min_value);
extend('max_value', max_value);
extend('confirmed', confirmed);
extend('alpha_spaces', alpha_spaces);
extend('url', {
  validate(value) {
    const urlregex = new RegExp(
      /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
    );
    return urlregex.test(value);
  },
  message: 'The {_field_} field valid must contain only format url'
});
extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' }: any = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value)
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?(\\d|)*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value)
    };
  },
  message: 'The {_field_} field must contain only decimal values'
});

// Custom rules
extend('different', different);
extend('other', value => value === 'other');

// Merge the messages.
const language = selectedLocale;
import(`vee-validate/dist/locale/${language}`).then(locale => {
  localize(selectedLocale, locale);
});

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
