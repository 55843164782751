import Swal from 'sweetalert2';
function showAlert(type: any, title: any, message?: string, input?: any) {
  Swal.fire({
    icon: type,
    title: title,
    text: message,
    footer: input || '',
    showClass: {
      popup: 'animate__animated animate__fadeInDown'
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp'
    }
  });
}

const showLoginModal = () => {
  return Swal.fire({
    title: 'Login',
    input: 'password',
    showCancelButton: true,
    confirmButtonText: 'Entrar',
    preConfirm: result => {
      return result;
    }
  });
};
export { showAlert, showLoginModal };
