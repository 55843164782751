import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { EntityRepository } from '@/settings/domain/entity/entity/EntityRepository';
import { Entity } from '@/settings/domain/entity/entity/Entity';

@Injectable()
export class EntityService implements EntityRepository {
  private readonly API_BASE_URI: string = localStorageModule().config.host.API_BASE_URI;
  private readonly API_ENTITY_SAVE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ENTITY_SAVE_PUBLIC;
  private readonly API_ENTITY_UPDATE: string = this.API_BASE_URI + localStorageModule().config.routes.API_ENTITY_UPDATE;
  private readonly API_ENTITY_FIND_BY_ID: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ENTITY_FIND_BY_ID;
  private readonly API_ENTITY_FIND_BY_EMAIL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ENTITY_FIND_BY_EMAIL;
  private readonly API_ENTITY_FIND_BY_DESCRIPTION_lIKE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ENTITY_FIND_BY_DESCRIPTION_lIKE;
  private readonly API_ENTITY_GET_MAX_CODE: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ENTITY_GET_MAX_CODE;
  private readonly API_ENTITY_FIND_ALL: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ENTITY_FIND_ALL_PAGE;
  private readonly API_ENTITY_DELETE_CONTACT: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ENTITY_DELETE_CONTACT;
  private readonly API_ENTITY_DELETE_ADDRESS: string =
    this.API_BASE_URI + localStorageModule().config.routes.API_ENTITY_DELETE_ADDRESS;

  async create(payload: Entity): Promise<any> {
    return await http.post<Entity>(this.API_ENTITY_SAVE, JSON.stringify(payload));
  }

  async update(payload: Entity): Promise<any> {
    return await http.post<Entity>(this.API_ENTITY_UPDATE, JSON.stringify(payload));
  }

  async findById(code: number): Promise<Entity[]> {
    return await http.get<Entity[]>(this.API_ENTITY_FIND_BY_ID + code);
  }

  async findByEmail(email: string): Promise<Entity[]> {
    return await http.get<Entity[]>(this.API_ENTITY_FIND_BY_EMAIL + email);
  }

  async findByFullNameLike(params: any): Promise<Entity[]> {
    return await http.get<Entity[]>(this.API_ENTITY_FIND_BY_DESCRIPTION_lIKE + `${params.text}/${params.type}`);
  }

  async getMaxCodeEntity(): Promise<number> {
    return await http.get<number>(this.API_ENTITY_GET_MAX_CODE);
  }

  async findAll(): Promise<Entity[]> {
    return await http.get<Entity[]>(this.API_ENTITY_FIND_ALL + '0');
  }

  async deleteAddress(objectIds: any): Promise<any> {
    return await http.get<Entity>(this.API_ENTITY_DELETE_ADDRESS + `${objectIds.entity}/${objectIds.address}`);
  }

  async deleteContact(objectIds: any): Promise<any> {
    return await http.get<Entity>(this.API_ENTITY_DELETE_CONTACT + `${objectIds.entity}/${objectIds.contact}`);
  }
}
