import { TYPES } from '@/core/config/Types';
import { Query } from '@/core/cqrs/Query';
import { Injectable } from '@/core/di/Injectable';
import { UserRepository } from '@/settings/domain/user/UserRepository';
import { Inject } from 'inversify-props';

@Injectable()
export class CheckUserExistence extends Query<Promise<boolean>, string> {
  public constructor(
    @Inject(TYPES.USER_REPOSITORY)
    private readonly userRepository: UserRepository
  ) {
    super();
  }

  async internalExecute(email: string): Promise<boolean> {
    return await this.userRepository.checkUserExistence(email);
  }
}
