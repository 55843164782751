import { errorMessage } from '../exception/NewNotificationException';
import { httpMethod } from '../exception/httpMethodsEnum';

const header: any = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const get = async <T>(url: string) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: header
  });

  errorMessage(response, httpMethod.GET);
  return (await response.json()) as T;
};

const post = async <T>(url: string, body: any) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: header,
    body
  });

  errorMessage(response, httpMethod.POST, body);
  return (await response.json()) as T;
};

const put = async <T>(url: string, body: any) => {
  const response = await fetch(url, {
    method: 'PUT',
    headers: header,
    body
  });

  errorMessage(response, httpMethod.PUT, body);
  return response;
};

export const httpFetchNoToken = {
  get,
  post,
  put
};
